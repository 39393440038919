import request from '../../services/request';
const URL_SALESCHALLENGE = '/Sales/Api/v1/Challenge/';
const SALESCHALLENGE_INFO = `${URL_SALESCHALLENGE}Info/`;

export async function getInfo(indicatorName, params, cancelToken) {
  return request(`${SALESCHALLENGE_INFO}${indicatorName}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getKAMInfo(params, cancelToken) {
  return request(`${SALESCHALLENGE_INFO}KAM`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
