import { combineReducers } from 'redux';
import addProduct from './addProduct';
import consultRules from './rules';
import filters from './filters';
import infoForAgreement from './infoForAgreement';
import module from './module';
import query from './query';
import registerTrade from './registerTrade';
import report from './reports';
import table from './table';
import views from './views';
import agreement from './agreement';
import uploadTradeAgreements from './uploadTradeAgreements';
import generateReport from './generateReport';
import clientKey from './clientKey';

export default combineReducers({
  addProduct,
  consultRules,
  filters,
  infoForAgreement,
  module,
  query,
  registerTrade,
  report,
  table,
  views,
  agreement,
  uploadTradeAgreements,
  generateReport,
  clientKey,
});
