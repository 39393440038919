import React, { useEffect, useCallback } from 'react';
import ProductInfo from '../../../common/ProductInfo';
import { connect } from 'react-redux';
import { querySave } from 'SalesChallengeRefactor/redux/actions/query';
import { getProductsPricesRequest } from 'SalesChallengeRefactor/redux/actions/registerSale';
import { saveUpdatePrices } from 'SalesChallengeRefactor/redux/actions/table';
import { useHistory } from 'react-router-dom';
import { MSG_019_020 } from 'SalesChallengeRefactor/utils/messages';
import { openNotification } from 'common/misc/openNotification';
import { formatPriceWithDecimals } from 'SalesChallengeRefactor/utils/index';

function ColumnLeft(props) {
  const {
    query,
    querySave: saveQuery,
    productInfo,
    productPrices,
    getProductsPricesRequest: getProductsPricesReq,
    saveUpdatePrices: updatePrices,
  } = props;
  const history = useHistory();

  const existPrices = useCallback(
    (infoPrices) => {
      if (
        infoPrices &&
        (typeof infoPrices.netPrice === 'undefined' || infoPrices.netPrice === null)
      ) {
        history.goBack();
        openNotification('error', MSG_019_020('Desafío de Ventas', 'precios'));
      }
    },
    [history]
  );

  useEffect(() => {
    if (productPrices?.data?.netPrice && query.product)
      updatePrices({ netPrice: productPrices?.data?.netPrice });
  }, [productPrices, updatePrices, query]);

  const askForPrices = (values) => {
    if (query && typeof query.product !== 'undefined') {
      const params = {
        zoneId: query?.zone,
        productId: query?.product,
        clientId: query?.client,
        countryId: values?.country,
        crop: values?.crop,
      };
      getProductsPricesReq({
        params: params,
        onSuccess: existPrices,
        origin: 'columnleft',
      });
    }
  };

  const onFormValuesChange = ({ changedValues, allValues, form }) => {
    const changedValueKey = Object.keys(changedValues)[0];
    if (changedValueKey === 'country') {
      form.setFieldsValue({
        crop: undefined,
      });
    }
    // Get the petition for products
    if (query.product) askForPrices(allValues);
    //Sharing the query with others
    saveQuery({ ...allValues });
  };

  const onDefaultValuesChange = ({ form }) => {
    form.setFieldsValue({
      moq: productPrices?.data?.moq,
      netPrice: `${formatPriceWithDecimals(productPrices?.data?.netPrice)} USD`,
    });
  };

  const handleOnQueryChange = ({ form }) => {
    if (!query.product) {
      form.setFieldsValue({
        country: undefined,
        crop: undefined,
        netPrice: undefined,
        moq: undefined,
      });
    }
  };

  const checkIfCanSetAutomaticlyValues = ({ productData, form }) => {
    if (query.product) {
      if (productData?.countries?.length === 1 && productData?.crops?.length === 1) {
        form.setFieldsValue({
          country: productData?.countries[0]?.key,
          crop: productData?.crops[0]?.key,
        });
        askForPrices({
          crop: productData?.crops[0]?.key,
          country: productData?.countries[0]?.key,
        });
      } else if (productData?.countries?.length === 1) {
        form.setFieldsValue({
          country: productData?.countries[0]?.key,
        });
        askForPrices({
          country: productData?.countries[0]?.key,
        });
      } else if (productData?.crops?.length === 1) {
        form.setFieldsValue({
          crop: productData?.crops[0]?.key,
        });
        askForPrices({
          crop: productData?.crops[0]?.key,
        });
      }
    }
  };

  return (
    <ProductInfo
      readOnly={!query?.product}
      onDefaultValuesChange={onDefaultValuesChange}
      productInfo={productInfo}
      productPrices={productPrices}
      onFormValuesChange={onFormValuesChange}
      isLoadingPrices={productPrices?.isLoading ?? false}
      defaultValues={{ netPrice: productPrices?.data?.netPrice, moq: productPrices?.data?.moq }}
      query={query}
      onQueryChange={handleOnQueryChange}
      onProductInfoDataChange={checkIfCanSetAutomaticlyValues}
    />
  );
}

function mapStateToProps(state) {
  return {
    query: state?.SALES_CHALLENGE?.query,
    productInfo: state?.SALES_CHALLENGE?.registerSales?.productInfo,
    productPrices: state?.SALES_CHALLENGE?.registerSales?.productPrices,
  };
}

export default connect(mapStateToProps, { querySave, getProductsPricesRequest, saveUpdatePrices })(
  ColumnLeft
);
