import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BModalHistory from 'common/components/BModalHistory';
import { challengeCommentsRequest } from 'SalesChallengeRefactor/redux/actions/challenge';

function HistoryAction(props) {
  const {
    challengeCommentsRequest: getHistory,
    zoneId,
    clientId,
    client,
    zone,
    year,
    challengeHistory,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (year) {
      if (zoneId && clientId) {
        getHistory({ params: { zoneId, clientId, year } });
      } else if (zoneId) {
        getHistory({ params: { zoneId, year } });
      }
      if (zone && client) {
        getHistory({ params: { zoneId: zone, clientId: client, year } });
      } else if (zone) {
        getHistory({ params: { zoneId: zone, year } });
      }
    }
  }, [zoneId, clientId, year, zone, client, getHistory]);

  return (
    <>
      {challengeHistory.length > 0 && (
        <BModalHistory dataSource={challengeHistory} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
      {(!challengeHistory || challengeHistory.length === 0) && null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    zoneId: state?.SALES_CHALLENGE?.query?.zoneId ?? false,
    clientId: state?.SALES_CHALLENGE?.query?.clientId ?? false,
    client: state?.SALES_CHALLENGE?.query?.client ?? false,
    zone: state?.SALES_CHALLENGE?.query?.zone ?? false,
    year: state?.SALES_CHALLENGE?.query?.year ?? false,
    challengeHistory: state?.SALES_CHALLENGE?.challenge?.historyChallenge?.data ?? false,
  };
}

export default connect(mapStateToProps, { challengeCommentsRequest })(HistoryAction);
