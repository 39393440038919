import axios from 'axios';
import { openNotification } from '../common';
import { notification } from 'antd';

const AUTH_API_URL = process.env.REACT_APP_API_GATEWAY;
let endPoint = null;
let onlyRequestInterceptor = null;

// eslint-disable-next-line no-unused-vars
let onlyResponseInterceptor = true;
let instance = axios.create({
  baseURL: AUTH_API_URL,
  Accept: 'application/json',
  AccessControlAllowOrigin: '*/*',
});
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
instance.defaults.headers.common['Accept'] = '*';
instance.defaults.headers.put['Content-Type'] = 'application/json;';
instance.defaults.headers.post['Content-Type'] = 'application/json;';

/* Handle Request */
const isHandlerEnabled = (config = {}) => {
  // eslint-disable-next-line no-prototype-builtins

  // eslint-disable-next-line no-prototype-builtins
  if (config.hasOwnProperty('handlerEnabled')) {
    if (Object.keys(config.handlerEnabled).length > 0) {
      if (config.handlerEnabled?.requestEnable) {
        onlyRequestInterceptor = true;
      }
      if (!config.handlerEnabled?.responseEnable) {
        onlyResponseInterceptor = false;
      }
    } else {
      return !config.handlerEnabled;
    }
  }
  return true;
};
const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const token = localStorage.getItem('token');
    if (token) request.headers.common['Authorization'] = `Bearer ${token}`;
  }
  if (request.customBaseURL) {
    request.baseURL = request.customBaseURL;
  }
  return request;
};
if (instance) {
  /* Handle request */
  instance.interceptors.request.use((request) => {
    endPoint = request.url;

    return requestHandler(request);
  });
  /* Handle response */
  instance.interceptors.response.use(
    (requestResponse) => {
      const { status } = requestResponse;
      let response;

      if (status < 299) {
        if (onlyRequestInterceptor) {
          onlyRequestInterceptor = null;
          return requestResponse;
        } else {
          response = requestResponse.data;
        }
      }
      return Promise.resolve(response);
    },
    (err) => {
      notification.destroy();
      if (navigator.onLine) {
        if (instance.isCancel(err)) {
          console.warn("Aborted request controlled, don't worry");
          return false;
        } else {
          console.error('The endpoint with error is:  ', endPoint);
        }
      }

      if (!err.response) {
        openNotification(
          'error',
          'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
        );
      }

      if (err.response?.status === 403) {
        openNotification(
          'error',
          'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
        );
      }
      if (err.response?.status === 401) {
        openNotification('error', `${err.response?.data ? err.response?.data?.message : ''}`);
        setTimeout(() => {
          localStorage.clear();
          window.location.replace('/');
        }, 1000);
      }
      if (err.response?.status === 400) {
        if (err?.response?.data?.Data) {
          openNotification(
            'error',
            `${err.response?.data?.Data ? err.response?.data?.Message : ''}`
          );
        } else {
          openNotification('error', `${err.response?.data ? err.response?.data?.message : ''}`);
        }
        if (!onlyResponseInterceptor) {
          return err.response;
        }
      } else if (err.response?.status > 500) {
        openNotification(
          'error',
          'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
        );
      }

      throw err;
    }
  );
}

export default instance;
