import React, { useState, useEffect, useContext } from 'react';
import { ReactComponent as Logo } from '../common/BASF-Logo_white.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appContext, layoutContext } from '../context';
import Styles from './update-password.module.css';
import BreadCrumbs from './Components/ContainerBreadCrumbs/BreadCrumbs';
import ContainerProfile from './Components/ContainerProfile/ContainerProfile';
import ModalUpdatePassword from './Components/ModalUpdatePassword/ModalUpdatePassword';
import NotificationAction from 'Notifications/containers/NotificationAction';
import { connect } from 'react-redux';
import {
  getNotificationRequest,
  handlePageTrueRequest,
} from 'Notifications/redux/actions/notifications';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getNotificationsModule from 'Notifications';
import { changePassword } from '../Layout/Components/ModalUpdatePassword/services';
import { openNotification } from '../common';
import {
  routesUsers,
  routesSalesChallenge,
  routesAccountStatus,
  routesPdfViewer,
} from './titleRoutes';

function Navbar(props) {
  const { getNotificationRequest: getNotifications, handlePageTrueRequest: handlePageTrue } = props;
  const { userInfo } = useContext(appContext);
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { t } = useTranslation(['layout']);
  const location = useLocation();
  const history = useHistory();
  const [module, setModule] = useState(undefined);
  const [parentModule, setParentModule] = useState(undefined);
  const [parentRoute, setParentRoute] = useState(undefined);
  const userProfile =
    userInfo.area === undefined || userInfo.area === null || userInfo.area === ''
      ? userInfo.profile
      : `Administrador de ${userInfo.area}`;
  const [updatePassword, setupdatePassword] = useState(false);
  const [showModalUpdatePassword, setShowModalUpdatePassword] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);

  let pageNumTrue = 1;

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    handlePageTrue(pageNumTrue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePageTrue]);

  useEffect(() => {
    let route = location.pathname.split('/');
    if (
      location.pathname.includes('historic-information/') ||
      location.pathname.includes('catalog/') ||
      location.pathname.includes('dashboards/') ||
      location.pathname.includes('dashboard/')
    ) {
      if (location.pathname.includes('catalog/trade-agreement-info/')) {
        //Nombres para Información de acuerdo comercial
        let parent = route[1];
        let child = route[2];
        let action = route[3];
        setParentModule(t(`breadcrumbs./${child}`));
        setParentRoute(`/${parent}/${child}`);
        setModule(t(`breadcrumbs.${child}${action ? action : ''}`));
      } else {
        let parent = route[1];
        let child = route[2];
        let action = route[3];
        setParentModule(t(`breadcrumbs./${parent}`));
        setParentRoute(`/${parent}`);
        setModule(t(`breadcrumbs.${child}${action ? action : ''}`));
      }
    } else if (
      location.pathname.includes('sales-challenge/') &&
      !location.pathname.includes('dashboard')
    ) {
      // Rutas de Desafio de ventas
      routesSalesChallenge(route, setParentModule, setParentRoute, t, setModule);
    } else if (route[1] === 'users') {
      // Rutas de Usuarios
      routesUsers(route, setModule, t, mainRoute, location);
    } else if (route[1] === 'account-status') {
      // Rutas de Estado de cuenta
      routesAccountStatus(
        route,
        location,
        setParentModule,
        setParentRoute,
        setModule,
        t,
        mainRoute
      );
    } else if (route[1] === 'pdf-viewer') {
      routesPdfViewer(t, setModule);
    } else {
      mainRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, t]);

  const mainRoute = () => {
    setParentModule(undefined);
    setModule(t(`breadcrumbs.${location.pathname}`));
  };

  const handleBack = () => {
    onChangeActiveComponent(undefined);
    history.push(parentRoute);
  };

  const showUpdatePassword = () => {
    if (updatePassword === false) {
      setupdatePassword(true);
    }
  };
  const hideUpdatePassword = () => {
    if (updatePassword === true) {
      setupdatePassword(false);
    }
  };

  const showModal = () => {
    setShowModalUpdatePassword(true);
  };

  const hideModal = (values) => {
    setModalLoader(true);
    changePassword(values)
      .then((response) => {
        setModalLoader(false);
        setShowModalUpdatePassword(false);
        openNotification('success', `${response.message ? response.message : ''}`);
      })
      .catch((err) => {
        setModalLoader(false);
        setShowModalUpdatePassword(false);
      });
  };

  return (
    <div data-testid="navbar" className="navbar">
      <div className="navbar-logo">
        <Logo className="navbar-logo-short" />
      </div>
      <div className={Styles['continer-breadcrums-profile']}>
        <BreadCrumbs parentModule={parentModule} handleBack={handleBack} module={module} />
        <ContainerProfile
          hideUpdatePassword={hideUpdatePassword}
          showUpdatePassword={showUpdatePassword}
          userProfile={userProfile}
          updatePassword={updatePassword}
          showModal={showModal}
        />
      </div>
      <NotificationAction />
      <ModalUpdatePassword
        showModalUpdatePassword={showModalUpdatePassword}
        hideModal={hideModal}
        loaderState={modalLoader}
        requiredPrevPass={true}
      />
    </div>
  );
}

const ConnectedTradeAgreements = connect(null, {
  getNotificationRequest,
  handlePageTrueRequest,
})(Navbar);

const DynamicModule = () => (
  <DynamicModuleLoader modules={[getNotificationsModule()]}>
    <ConnectedTradeAgreements />
  </DynamicModuleLoader>
);
export default DynamicModule;
