import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import { Clients, Territory, BusinessRules, Family, Products, Years } from 'RootModule/catalogs';
import { connect } from 'react-redux';
import { querySave, queryClean } from 'TradeAgreements/redux/actions/query';
import { setCurrentView } from 'TradeAgreements/redux/actions/views';
import { ResetButton } from 'common/components';
import { cleanTopFilters } from '../../redux/actions/filters';
import { cleanInfo } from '../../redux/actions/info';
import {
  cleanInfoForAgreement,
  clientSelected,
  territorySelected,
} from '../../redux/actions/infoForAgreement';
import { cleanIndicators } from 'TradeAgreements/redux/actions/indicators';
import useWasRendered from '../../hooks/useWasRendered';
import useFilters from '../../hooks/useFilters';
import ActionsGroup from 'TradeAgreements/components/ActionsGroup';
import { processStepIdEnum } from 'common/models/enums';
import { getDaysLeftRequest } from 'TradeAgreements/redux/actions/registerTrade';
import { setClientKey, setCurrentClientData } from 'TradeAgreements/redux/actions/clientKey';
import ReviewActionsContainer from '../ReviewActionsContainer/ReviewActionsContainer';

const layoutFilters = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 4,
  xl: 4,
};
const {
  GENERATE_TRADE_AGREEMENT,
  VALIDITY_PROCESS,
  UPLOAD_TRADE_AGREEMENT,
  TRADE_AGREEMENT_ADJUST,
} = processStepIdEnum;

function TopFiltersNationalClients(props) {
  const {
    querySave: saveQuery,
    setCurrentView: setView,
    queryClean: cleanQuery,
    cleanTopFilters: cleanFilters,
    cleanInfo: infoClean,
    cleanInfoForAgreement: cleanAgreement,
    clientSelected: setClientSelected,
    cleanIndicators: indicatorsClean,
    onChangeActiveComponent,
    isNational,
    territorySelected: setTerritorySelected,
    getDaysLeftRequest: getDaysLeft,
    clientCatalogs,
    query,
    setClientKey: clientKeySet,
    setCurrentClientData: currentClientDataSet,
  } = props;

  const { wasRendered, setWasRendered } = useWasRendered();
  const [form] = Form.useForm();
  const [disabledFilters, setDisabledFilters] = useState({});
  const [allowRequest, setAllowRequests] = useState({});
  const [requestParamsSubscription, setRequestParamsSubscription] = useState({});

  const cleanAllFilters = useCallback(() => {
    cleanFilters();
  }, [cleanFilters]);

  useEffect(() => {
    if (!wasRendered.current) {
      saveQuery({ state: 'idle', showBruteValue: true });
      cleanAllFilters();
      setWasRendered();
      getDaysLeft({ params: { processId: UPLOAD_TRADE_AGREEMENT } }); //6 días restantes
      getDaysLeft({ params: { processId: VALIDITY_PROCESS } }); //fecha de proceso
      getDaysLeft({ params: { processId: GENERATE_TRADE_AGREEMENT } });
      getDaysLeft({ params: { processId: TRADE_AGREEMENT_ADJUST } });
    }
  }, [saveQuery, wasRendered, setWasRendered, cleanAllFilters, getDaysLeft]);

  const {
    orchestrateFiltersChange,
    orchestrateFiltersReset,
    initialDisabledFilters,
    initialAllowRequest,
    initialRequestParamsSubscription,
  } = useFilters(isNational, false, query);

  useEffect(() => {
    if (initialDisabledFilters && initialAllowRequest && initialRequestParamsSubscription) {
      setDisabledFilters({ ...initialDisabledFilters });
      setAllowRequests({ ...initialAllowRequest });
      setRequestParamsSubscription({ ...initialRequestParamsSubscription });
      setWasRendered(true);
    }
  }, [
    initialAllowRequest,
    initialDisabledFilters,
    initialRequestParamsSubscription,
    wasRendered,
    setWasRendered,
  ]);

  const handleOnValuesChange = useCallback(
    ({ changedValues, _allValues }) => {
      const currentChange = Object.keys(changedValues)[0];
      const formValues = form.getFieldsValue();
      onChangeActiveComponent('trade-agreements');

      orchestrateFiltersChange({
        changedValues,
        currentChange,
        form,
        formValues,
        requestParamsSubscription,
        setAllowRequests,
        setClientSelected,
        setDisabledFilters,
        setRequestParamsSubscription,
        setTerritorySelected,
        isNational,
        clientCatalogs,
      });
      let selectedClient = null;
      if (clientCatalogs && clientCatalogs.length > 0) {
        selectedClient = clientCatalogs.find(
          (client) => client.key === form.getFieldValue('clientId')
        );
      }

      if (currentChange === 'businessRule' && !selectedClient?.isNational) {
        setView('clientId');
      } else if (
        selectedClient?.isNational &&
        currentChange !== 'businessRule' &&
        currentChange !== 'family' &&
        currentChange !== 'productId'
      ) {
        setView('subRegionId');
      } else if (currentChange === 'businessRule' && selectedClient?.isNational) {
        setView('clientId');
      } else {
        setView(currentChange);
      }
      //Sahring this part of the query with other components
      const queryValues = form.getFieldsValue();

      saveQuery({ ...queryValues, state: 'changed' });
    },
    [
      clientCatalogs,
      form,
      onChangeActiveComponent,
      orchestrateFiltersChange,
      requestParamsSubscription,
      setClientSelected,
      setTerritorySelected,
      isNational,
      setView,
      saveQuery,
    ]
  );

  const commonFilterProps = {
    allowClear: false,
  };

  const handleResetButton = () => {
    form.resetFields();
    setDisabledFilters({
      ...initialDisabledFilters,
    });

    setAllowRequests({
      ...initialAllowRequest,
    });

    orchestrateFiltersReset({
      setDisabledFilters,
      setRequestParamsSubscription,
      setAllowRequests,
      form,
    });

    const formValues = form.getFieldsValue();
    cleanQuery({ state: 'idle', year: formValues.year, showBruteValue: true });
    cleanAgreement();
    cleanAllFilters();
    infoClean();
    indicatorsClean();
  };

  const setAutomaticallyFirstOption = (options, filterName = 'nonde') => {
    if (options?.length > 0) {
      form.setFieldsValue({
        [filterName]: options[0]?.key,
      });
      const allValues = form.getFieldsValue();
      const changedValues = { [filterName]: options[0]?.key };
      handleOnValuesChange({ changedValues, allValues });
    }
  };

  const setAutomaticallyAnOption = (options, optionKey = 1, filterName = 'none') => {
    if (options?.length > 0) {
      const optionIndex = options.findIndex((option) => option?.key === optionKey);
      form.setFieldsValue({
        [filterName]: options[optionIndex]?.key ?? undefined,
      });
      const allValues = form.getFieldsValue();
      const changedValues = { [filterName]: options[optionIndex]?.key };
      handleOnValuesChange({ changedValues, allValues });
    }
  };
  const algo = (param) => {
    clientKeySet(param.clientId);
    currentClientDataSet(param);
  };
  const mustShowTerritory = () => {
    const getCurrentClientData = () => {
      if (!clientCatalogs || !form.getFieldValue().clientId) return null;
      return clientCatalogs.find((client) => client.key === form.getFieldValue().clientId);
    };
    const currentClient = getCurrentClientData();
    if (!currentClient) return false;
    return Boolean(currentClient.isNational);
  };

  return (
    <>
      <Form
        form={form}
        onValuesChange={(changedValues, allValues) => {
          handleOnValuesChange({ changedValues, allValues });
          algo(allValues);
        }}
        layout="horizontal"
        style={{ display: 'flex', justifyContent: 'start' }}
      >
        <Row>
          <Col {...layoutFilters}>
            <Years
              disabled={disabledFilters.year}
              askFromService={allowRequest.year ?? false}
              sourceId="Agreements"
              requestParams={requestParamsSubscription?.['year']}
              onSuccess={(options) => setAutomaticallyFirstOption(options, 'year')}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Clients
              disabled={disabledFilters.clientId}
              askFromService={allowRequest.clientId ?? false}
              sourceId="Agreements"
              requestParams={requestParamsSubscription?.['clientId']}
              {...commonFilterProps}
            />
          </Col>
          {mustShowTerritory() && (
            <Col {...layoutFilters}>
              <Territory
                disabled={disabledFilters.territoryId ?? null}
                askFromService={allowRequest.territoryId ?? null}
                sourceId="Agreements"
                requestParams={requestParamsSubscription?.['territoryId']}
                {...commonFilterProps}
              />
            </Col>
          )}
          <Col {...layoutFilters}>
            <BusinessRules
              disabled={disabledFilters.businessRule}
              askFromService={allowRequest.businessRule ?? false}
              sourceId="Agreements"
              requestParams={requestParamsSubscription?.['businessRule']}
              onSuccess={(options) => setAutomaticallyAnOption(options, 3, 'businessRule')}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Family
              disabled={disabledFilters.family}
              askFromService={allowRequest.family ?? false}
              sourceId="Agreements"
              requestParams={requestParamsSubscription?.['family']}
              {...commonFilterProps}
            />
          </Col>

          <Col {...layoutFilters}>
            <Products
              disabled={disabledFilters.productId}
              askFromService={allowRequest.productId ?? false}
              sourceId="Agreements"
              requestParams={requestParamsSubscription?.['productId']}
              {...commonFilterProps}
            />
          </Col>
        </Row>
      </Form>
      <Row gutter={32} justify="end">
        <Col>
          <ResetButton onClick={handleResetButton} />
        </Col>
        <Col>
          <ReviewActionsContainer />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <ActionsGroup />
        </Col>
      </Row>
    </>
  );
}

TopFiltersNationalClients.propTypes = {
  intialValues: PropTypes.object,
};

TopFiltersNationalClients.defaultProps = {
  intialValues: {},
};

function mapStateToProps(state) {
  return {
    isLoadingReport: state?.SALES_CHALLENGE?.report?.isDownloading,
    isNational: state.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.isNational,
    clientCatalogs: state?.APP?.catalogs?.clients?.data ?? false,
    query: state.TRADE_AGREEMENTS?.query,
    validityProcess: state.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS,
  };
}

export default connect(mapStateToProps, {
  querySave,
  setCurrentView,
  queryClean,
  cleanTopFilters,
  cleanInfo,
  cleanInfoForAgreement,
  cleanIndicators,
  clientSelected,
  territorySelected,
  getDaysLeftRequest,
  setClientKey,
  setCurrentClientData,
})(TopFiltersNationalClients);
