export const aproveTerritoryModal = (clients) => ({
  modal: {
    title: 'Validar desafio de ventas',
    content: `¿Está seguro que desea aprobar el desafio de ventas para el territorio y sus zonas asociadas? ${
      // eslint-disable-next-line no-nested-ternary
      clients === 1
        ? `El desafío de ventas cuenta con ${clients} cliente rechazado, al aprobarlo, este client pasará a estatus "Aprobado".`
        : clients > 0
        ? `El desafío de ventas cuenta con ${clients} clientes rechazados, al aprobarlo, estos clientes pasarán a estatus "Aprobado".`
        : ''
    }`,
  },
});

export const rejectTerritoryModal = {
  modal: {
    title: 'Validar desafio de ventas',
    content:
      '¿Está seguro que desea rechazar el desafio de ventas para el territorio y sus zonas asociadas?',
  },
};

export const aproveZoneModal = (clients) => ({
  modal: {
    title: 'Validar desafio de ventas',
    content: `¿Está seguro que desea aprobar el desafio de ventas para la zona? ${
      // eslint-disable-next-line no-nested-ternary
      clients === 1
        ? `El desafío de ventas cuenta con ${clients} cliente rechazado, al aprobarlo, este cliente pasará a estatus "Aprobado".`
        : clients > 0
        ? `El desafío de ventas cuenta con ${clients} clientes rechazados, al aprobarlo, estos clientes pasarán a estatus "Aprobado".`
        : ''
    }`,
  },
});

export const rejectZoneModal = {
  modal: {
    title: 'Validar desafio de ventas',
    content: '¿Está seguro que desea rechazar el desafio de ventas para la zona?',
  },
};

export const aproveClientModal = {
  modal: {
    title: 'Validar desafio de ventas',
    content: '¿Está seguro que desea aprobar el desafio de ventas para el cliente?',
  },
};

export const rejectClientModal = {
  modal: {
    title: 'Validar desafio de ventas',
    content: '¿Está seguro que desea rechazar el desafio de ventas para el cliente?',
  },
};
