/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from 'react';

import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { BInput } from 'common/components';
import { Territory, Zones, Clients, Products, BusinessLine } from 'RootModule/catalogs';
import { Row, Col, Form } from 'antd';
import SalesGoalsTable from './salesGoalsTable';
import getRootModule from 'RootModule';
import { getSalesGoalYear, registerSaleGoal, validateExistSale } from './services';
import { ActionButtons, openNotification } from '../common/';
import { layoutContext } from '../context';
import { useHistory } from 'react-router-dom';

function SalesGoalForm() {
  const [form] = Form.useForm();
  const layoutFilters = {
    xs: 24,
    sm: 12,
    md: 6,
    lg: 8,
    xlg: 8,
  };

  const history = useHistory();
  const { onChangeActiveComponent } = useContext(layoutContext);

  const [data, setData] = useState({});

  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [volumeSum, setVolumenSum] = useState(0);
  const [valueSum, setValueSum] = useState(0);
  const [year, setYear] = useState(0);
  const [disabledSelects, setDisabledSelects] = useState({
    territoryId: false,
    zoneId: true,
    productNumber: true,
    businessLine: true,
  });
  const [monthsArray, setMonthsArray] = useState([
    { month: '1', volume: 0, usdAmount: 0 },
    { month: '2', volume: 0, usdAmount: 0 },
    { month: '3', volume: 0, usdAmount: 0 },
    { month: '4', volume: 0, usdAmount: 0 },
    { month: '5', volume: 0, usdAmount: 0 },
    { month: '6', volume: 0, usdAmount: 0 },
    { month: '7', volume: 0, usdAmount: 0 },
    { month: '8', volume: 0, usdAmount: 0 },
    { month: '9', volume: 0, usdAmount: 0 },
    { month: '10', volume: 0, usdAmount: 0 },
    { month: '11', volume: 0, usdAmount: 0 },
    { month: '12', volume: 0, usdAmount: 0 },
  ]);

  let accumnulatorUsd = 0;
  let accumnulatorVolumen = 0;

  monthsArray.forEach((value) => {
    if (value.usdAmount === null) {
      value.usdAmount = 0;
    }
    if (value.volume === null) {
      value.volume = 0;
    }
    accumnulatorUsd += value.usdAmount;
  });
  monthsArray.forEach((value) => {
    if (value.usdAmount === null) {
      value.usdAmount = 0;
    }
    if (value.volume === null) {
      value.volume = 0;
    }

    accumnulatorVolumen += value.volume;
  });

  const [requestParams, setRequestParams] = useState({
    zoneId: {},
    productNumber: {},
    businessLine: {},
  });

  const [requestPermissions, setRequestPermission] = useState({
    productNumber: false,
    businessLine: false,
  });

  useEffect(() => {
    getSalesGoalYear().then((res) => {
      setYear(res.data[0]);
    });
  }, []);

  useEffect(() => {
    setValueSum(accumnulatorUsd);
    setVolumenSum(accumnulatorVolumen);
  }, [accumnulatorUsd, accumnulatorVolumen]);

  const onCancel = () => {
    history.push('/catalog');
  };

  const validateForm = () => {
    const allValues = form.getFieldsValue();
    if (
      allValues.businessLine !== undefined &&
      allValues.clientName !== undefined &&
      allValues.clientSoldTo !== undefined &&
      allValues.productLabel !== undefined &&
      allValues.productNumber !== undefined &&
      allValues.territoryId !== undefined &&
      allValues.zoneId !== undefined
    ) {
      setDisabledSubmit(false);
    } else {
      setDisabledSubmit(true);
    }
  };

  const handleOnValuesChange = (changedValues) => {
    const allValues = form.getFieldsValue();

    if (volumeSum > 0) {
      validateForm();
    }

    let cont = 0;
    let contValues = Object.values(allValues);

    contValues.map((values) => {
      if (values === undefined || values === '') {
        cont++;
      }
    });
    if (cont !== contValues.length || volumeSum > 0 || valueSum > 0) {
      onChangeActiveComponent('sales-challenge');
    } else {
      onChangeActiveComponent(undefined);
    }

    //validateForm();
    const currentChange = Object.keys(changedValues)[0];
    switch (currentChange) {
      case 'territoryId':
        if (allValues.territoryId === undefined) {
          setDisabledSelects({ territoryId: false, zoneId: true });
        } else {
          setDisabledSelects({ territoryId: false, zoneId: false });
        }
        form.setFieldsValue({
          zoneId: undefined,
          productNumber: undefined,
          productLabel: undefined,
        });
        setRequestParams({
          ...requestParams,
          zoneId: { territories: allValues.territoryId },
          productNumber: { territories: allValues.territoryId },
        });
        setRequestPermission({
          ...requestPermissions,
          productNumber: true,
        });
        break;
      case 'zoneId':
        if (
          allValues.territoryId !== undefined &&
          allValues.businessLine !== undefined &&
          (allValues.clientSoldTo !== undefined || allValues.clientName !== undefined) &&
          (allValues.productNumber !== undefined || allValues.productLabel !== undefined) &&
          allValues.zoneId !== undefined
        ) {
          let idClient = allValues.clientSoldTo ? allValues.clientSoldTo : allValues.clientName;
          let idProducto = allValues.productNumber
            ? allValues.productNumber
            : allValues.productLabel;
          validateExistSale(
            allValues.territoryId,
            allValues.businessLine,
            allValues.zoneId,
            idClient,
            idProducto
          ).then((res) => {
            if (res.data === true) {
              openNotification(
                'error',
                `La información ingresada para la meta de venta ya se encuentra registrada en el sistema`
              );
            }
          });
        }
        break;

      case 'businessLine':
        if (
          allValues.territoryId !== undefined &&
          allValues.businessLine !== undefined &&
          (allValues.clientSoldTo !== undefined || allValues.clientName !== undefined) &&
          (allValues.productNumber !== undefined || allValues.productLabel !== undefined) &&
          allValues.zoneId !== undefined
        ) {
          let idClient = allValues.clientSoldTo ? allValues.clientSoldTo : allValues.clientName;
          let idProducto = allValues.productNumber
            ? allValues.productNumber
            : allValues.productLabel;
          validateExistSale(
            allValues.territoryId,
            allValues.businessLine,
            allValues.zoneId,
            idClient,
            idProducto
          ).then((res) => {
            if (res.data === true) {
              openNotification(
                'error',
                `La información ingresada para la meta de venta ya se encuentra registrada en el sistema`
              );
            }
          });
        }

        break;

      default:
        break;
    }
  };

  const handleLinkedSelect = (linked, value) => {
    const allValues = form.getFieldsValue();

    if (typeof value !== 'object') {
      if (linked === 'productLabel' || linked === 'productNumber') {
        if (
          allValues.territoryId !== undefined &&
          allValues.businessLine !== undefined &&
          (allValues.clientSoldTo !== undefined || allValues.clientName !== undefined) &&
          allValues.zoneId !== undefined
        ) {
          let clientId = allValues.clientSoldTo ? allValues.clientSoldTo : allValues.clientName;

          validateExistSale(
            allValues.territoryId,
            allValues.businessLine,
            allValues.zoneId,
            clientId,
            value
          ).then((res) => {
            if (res.data === true) {
              openNotification(
                'error',
                `La información ingresada para la meta de venta ya se encuentra registrada en el sistema`
              );
            }
          });
        }
      }

      if (linked === 'clientSoldTo' || linked === 'clientName') {
        /* if (
          allValues.territoryId !== undefined &&
          allValues.businessLine !== undefined &&
          (allValues.productNumber !== undefined || allValues.productLabel !== undefined) &&
          allValues.zoneId !== undefined
        ) {
          
          let productId = allValues.productNumber
            ? allValues.productNumber
            : allValues.productLabel;

          validateExistSale(
            allValues.territoryId,
            allValues.businessLine,
            allValues.zoneId,
            value,
            productId
          ).then((res) => {
            if (res.data === true) {
              openNotification(
                'error',
                `La información ingresada para la meta de venta ya se encuentra registrada en el sistema`
              );
            }
          });
        }*/
        form.setFieldsValue({
          businessLine: undefined,
        });
        setRequestParams({
          ...requestParams,
          businessLine: {
            clientSoldTo: value,
          },
        });
        setRequestPermission({
          ...requestPermissions,
          businessLine: true,
        });
        setDisabledSelects({
          ...disabledSelects,
          businessLine: false,
        });
      }
      form.setFieldsValue({ [linked]: value });
      if (volumeSum > 0) {
        validateForm();
      }
    }
  };

  const autoSelectOnlyOption = (options) => {
    const allValues = form.getFieldsValue();

    if (options.length === 1) {
      form.setFieldsValue({ businessLine: options[0].key });

      if (
        allValues.territoryId !== undefined &&
        options[0].key !== undefined &&
        (allValues.clientSoldTo !== undefined || allValues.clientName !== undefined) &&
        (allValues.productNumber !== undefined || allValues.productLabel !== undefined) &&
        allValues.zoneId !== undefined
      ) {
        let idClient = allValues.clientSoldTo ? allValues.clientSoldTo : allValues.clientName;
        let idProducto = allValues.productNumber ? allValues.productNumber : allValues.productLabel;
        validateExistSale(
          allValues.territoryId,
          options[0].key,
          allValues.zoneId,
          idClient,
          idProducto
        ).then((res) => {
          if (res.data === true) {
            openNotification(
              'error',
              `La información ingresada para la meta de venta ya se encuentra registrada en el sistema`
            );
          }
        });
      }
    }
    if (volumeSum > 0) {
      validateForm();
    }
  };
  const onChangeTerritory = () => {
    if (volumeSum > 0) {
      validateForm();
    }
  };
  const onsubmit = () => {
    const allValues = form.getFieldsValue();
    const month = monthsArray.filter((values) => {
      values.month = parseInt(values.month);
      return values.volume !== undefined;
    });

    const valuesForm = {
      lineId: allValues.businessLine,
      zoneId: allValues.zoneId,
      soldTo: allValues.clientSoldTo,
      productNumber: allValues.productNumber,
      crop: allValues.ap,
      saleMonth: month,
      year: year,
    };

    // history.push('/catalog/selling-goals');
    registerSaleGoal(valuesForm).then((res) => {
      if (res.data) {
        openNotification('success', `${res.message}`);
        onChangeActiveComponent(undefined);
        setTimeout(() => {
          history.push('/catalog/selling-goals');
        }, 3000);
      } else {
        openNotification('error', `${res.message}`);
      }
    });
  };
  // Create our number formatter.
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <Form form={form} onValuesChange={handleOnValuesChange}>
      <div
        style={{
          backgroundColor: '#fbfbfb',
          paddingTop: '17px',
          paddingLeft: '17px',
          marginTop: '5px',
        }}
      >
        <Row>
          <Col
            {...layoutFilters}
            style={{
              color: 'rgb(33,160,210)',
              margin: '5px 0px',
              textAlign: 'left',
              fontWeight: '500',
              fontSize: '18px',
              height: '25px',
              fontFamily: 'Montserrat',
              data: data,
            }}
          >
            Estructura comercial
          </Col>
        </Row>
        <Row>
          <Col {...layoutFilters} style={{ marginTop: '-15px' }}>
            <Territory
              name="territoryId"
              isRequired
              allowClear={false}
              onValuesChange={onChangeTerritory}
              disabled={disabledSelects.territoryId}
            />
          </Col>
          <Col {...layoutFilters} style={{ marginTop: '-15px' }}>
            <Zones
              name="zoneId"
              isRequired
              allowClear={false}
              disabled={disabledSelects.zoneId}
              requestParams={requestParams.zoneId}
              sourceId="Fixed"
            />
          </Col>
        </Row>
        <Row>
          <Col
            {...layoutFilters}
            style={{
              color: 'rgb(33,160,210)',
              textAlign: 'left',
              fontWeight: '500',
              fontSize: '18px',
              height: '25px',
              fontFamily: 'Montserrat',
              marginTop: '-15px',
              marginBottom: '5px',
            }}
          >
            Información general
          </Col>
        </Row>
        <Row>
          <Col {...layoutFilters} style={{ marginTop: '-15px' }}>
            <BInput
              name="ap"
              label="AP:"
              placeholder={'Escriba AP'}
              disabled={disabledSelects.ap}
            />
          </Col>
          <Col {...layoutFilters} style={{ marginTop: '-15px' }}>
            <Clients
              label="Sold to"
              isRequired
              optionKey="soldTo"
              valueKey="soldTo"
              labelKey="soldTo"
              sourceId="BySoldTo"
              name="clientSoldTo"
              allowClear={false}
              onChange={(value) => handleLinkedSelect('clientName', value)}
            />
          </Col>
          <Col {...layoutFilters} style={{ marginTop: '-15px' }}>
            <Clients
              label="Cliente"
              isRequired
              optionKey="soldTo"
              valueKey="soldTo"
              labelKey="name"
              sourceId="BySoldTo"
              name="clientName"
              allowClear={false}
              onChange={(value) => handleLinkedSelect('clientSoldTo', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutFilters} style={{ marginTop: '-15px' }}>
            <Products
              label="*# de producto"
              labelKey="productNumber"
              optionKey="productNumber"
              valueKey="productNumber"
              sourceId="ByProductNumber"
              name="productNumber"
              allowClear={false}
              requestParams={requestParams.productNumber}
              askFromService={requestPermissions.productNumber}
              disabled={disabledSelects.productNumber}
              onChange={(value) => handleLinkedSelect('productLabel', value)}
            />
          </Col>
          <Col {...layoutFilters} style={{ marginTop: '-15px' }}>
            <Products
              isRequired
              sourceId="ByProductNumber"
              optionKey="productNumber"
              valueKey="productNumber"
              name="productLabel"
              labelKey="name"
              allowClear={false}
              requestParams={requestParams.productNumber}
              askFromService={requestPermissions.productNumber}
              disabled={disabledSelects.productNumber}
              onChange={(value) => handleLinkedSelect('productNumber', value)}
            />
          </Col>
          <Col {...layoutFilters} style={{ marginTop: '-15px' }}>
            <BusinessLine
              isRequired
              sourceId="Fixed"
              allowClear={false}
              requestParams={requestParams.businessLine}
              onSuccess={autoSelectOnlyOption}
              name="businessLine"
              askFromService={requestPermissions.businessLine}
              disabled={disabledSelects.businessLine}
            />
          </Col>
        </Row>
        <Row>
          <Col
            {...layoutFilters}
            style={{
              color: 'rgb(33,160,210)',
              textAlign: 'left',
              fontWeight: '500',
              fontSize: '18px',
              height: '25px',
              fontFamily: 'Montserrat',
              marginTop: '-15px',
            }}
          >
            Información de meta de venta
          </Col>
        </Row>
        <Row>
          <div style={{ width: '100%' }}>
            <SalesGoalsTable
              setForm={setData}
              setDisabledSubmit={setDisabledSubmit}
              form={form}
              validateForm={validateForm}
              monthsArray={monthsArray}
              setMonthsArray={setMonthsArray}
            />
          </div>
        </Row>
        <Row>
          <Col lg={4} xlg={4}>
            <BInput
              label="Sumatoria de volumen (Kg/L)"
              disabled={true}
              value={`${formatter.format(volumeSum).substring(1)} Kg/L`}
            />
          </Col>
          <Col lg={4} xlg={4}>
            <BInput
              label="Sumatoria de valor (USD)"
              disabled={true}
              value={`${formatter.format(valueSum)}`}
            />
          </Col>
          <Col lg={4} xlg={4}>
            <BInput label="Año" disabled={true} value={year} />
          </Col>
        </Row>
      </div>
      <ActionButtons onCancel={onCancel} onSubmit={onsubmit} disabled={disabledSubmit} />
    </Form>
  );
}

const SalesGoals = () => (
  <DynamicModuleLoader modules={[getRootModule()]}>
    <SalesGoalForm />
  </DynamicModuleLoader>
);

export default SalesGoals;
