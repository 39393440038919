import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Row, Col, Form } from 'antd';
import { connect } from 'react-redux';
import { Years, Clients, Territory, BusinessRules, Family, Products } from 'RootModule/catalogs/';
import { ResetButton } from 'common/components';
import RemainingDays from '../../common/RemainingDays';
import { querySave, queryClean } from '../../redux/actions/query';
import { cleanTopFilters } from '../../redux/actions/filters';
import { cleanViewsRequest } from 'TradeAgreements/redux/actions/views';
import { cleanRegisterTrade, getProductsRequest } from '../../redux/actions/registerTrade';
import {
  cleanTable as cleanTableRequest,
  validateTableRequest,
} from 'TradeAgreements/redux/actions/table';
import ToggleModalAddProduct from 'common/components/BToggleModalControl';
import AddProductModal from 'common/components/AddProductModal';
import { MSG_003 } from 'SalesChallengeRefactor/utils/messages';
import { openNotification } from 'common';
import { familyRequest } from 'RootModule/redux/actions/catalogs';
import {
  addProductRequest,
  productsSelected,
  productSelectedClean,
} from 'TradeAgreements/redux/actions/addProduct';

const layoutFilters = {
  xs: 24,
  sm: 12,
  md: 6,
  lg: 4,
  xlg: 5,
};
const filtersNameEnum = {
  YEARS: 'years',
  ZONE: 'zone',
  CLIENT: 'client',
  FAMILY: 'family',
  PRODUCT: 'product',
};

const productsHasAgreementVolume = (el) => {
  if (el) {
    return {
      className: el.hasMontlyVolume ? 'element-registered' : 'not-special',
    };
  }
};

function TopFiltersToRegister(props) {
  const {
    onChangeActiveComponent,
    querySave: saveQuery,
    cleanRegisterTrade: cleanRegister,
    validateTableRequest: validateTableMandatory,
    cleanTableRequest: cleanTable,
    cleanViewsRequest: cleanViews,
    daysLeft,
    territoryId,
    getProductsRequest: getProductInfo,
    agreementType,
    isAddingProduct,
    productsSelected: selectedProduct,
    productSelectedClean: cleanSelectedProducts,
    cleanAddProducts: cleanProducts,
    addProductRequest: addProduct,
    clientId,
    familyRequest: familyReq,
    validityProcessYear,
    consultClientKey,
    //consultClientIsNational,
    tableIsValid,
    keyClient,
  } = props;
  const ref = useRef();

  const [form] = Form.useForm();
  const [wasSelected, setWasSelected] = useState(false);
  const [addOkIsDisabled, setAddOkIsDisabled] = useState(true);
  const [isNational, setIsNational] = useState(false);

  const [disabledFilters, setDisabledFilters] = useState({
    lineId: true,
    territoryId: true,
    clientId: true,
    businessRule: true,
    family: true,
    productId: true,
  });
  const [allowRequest, setAllowRequests] = useState({
    lineId: false,
    territoryId: null,
    clientId: true,
    businessRule: false,
    family: false,
    productId: false,
  });
  const [requestParamsSubscription, setRequestParamsSubscription] = useState({
    lineId: {},
    territoryId: {},
    clientId: {},
    businessRule: {},
    family: { owner: true },
    productId: { owner: true },
  });

  useEffect(() => {
    cleanTable();
  }, [cleanTable]);

  const hasTerritoryId = useCallback(() => {
    return territoryId ? { territoryId } : {};
  }, [territoryId]);

  const handleOnFormValuesChange = ({ changedValues, _allValues }) => {
    if (typeof tableIsValid.state === 'boolean' && !tableIsValid.state && !tableIsValid.wasSend) {
      setTimeout(() => {
        validateTableMandatory({ state: false, wasSend: true });
      }, 600);
      setTimeout(() => {
        openNotification('error', MSG_003);
      }, 1000);
    }

    const currentChange = Object.keys(changedValues)[0];
    const formValues = form.getFieldsValue();
    switch (currentChange) {
      case 'territoryId':
        form.setFieldsValue({
          clientId: undefined,
          businessRule: undefined,
          family: undefined,
          productId: undefined,
        });
        setDisabledFilters({
          lineId: false,
          territoryId: null,
          clientId: false,
          businessRule: true,
          family: true,
          productId: true,
        });
        setRequestParamsSubscription({
          ...requestParamsSubscription,
          clientId: {
            ...requestParamsSubscription.clientId,
            territoryId: formValues.territoryId,
            year: formValues.year,
          },
          family: { ...requestParamsSubscription.family, territoryId: formValues.territoryId },
          productId: {
            ...requestParamsSubscription.productId,
            territoryId: formValues.territoryId,
          },
        });
        setAllowRequests({
          lineId: false,
          territoryId: null,
          clientId: true,
          businessRule: false,
          family: false,
          productId: false,
        });
        break;

      case 'clientId':
        form.setFieldsValue({
          businessRule: undefined,
          family: undefined,
          productId: undefined,
        });
        setDisabledFilters({
          lineId: false,

          territoryId: null,
          clientId: false,
          businessRule: false,
          family: true,
          productId: true,
        });
        setRequestParamsSubscription({
          ...requestParamsSubscription,
          family: {
            ...requestParamsSubscription.family,
            clientId: formValues.clientId,
            territoryId: formValues.territoryId,
            year: formValues.year,
          },
          productId: {
            ...requestParamsSubscription.productId,
            clientId: formValues.clientId,
            year: formValues.year,
          },
        });
        setAllowRequests({
          lineId: false,
          territoryId: null,
          clientId: false,
          businessRule: true,
          family: false,
          productId: false,
        });
        break;

      case 'businessRule':
        form.setFieldsValue({
          family: undefined,
          productId: undefined,
        });
        setDisabledFilters({
          lineId: false,

          territoryId: null,
          clientId: false,
          businessRule: false,
          family: false,
          productId: true,
        });
        setRequestParamsSubscription({
          ...requestParamsSubscription,
          family: {
            ...requestParamsSubscription.family,
            policyTypeId: formValues.businessRule,
            ...hasTerritoryId(),
          },
          productId: {
            ...requestParamsSubscription.productId,
            policyTypeId: formValues.businessRule,
          },
        });
        setAllowRequests({
          lineId: false,
          territoryId: null,
          clientId: false,
          businessRule: false,
          family: true,
          productId: false,
        });
        break;

      case 'family':
        form.setFieldsValue({
          productId: undefined,
        });
        setDisabledFilters({
          year: false,
          regionId: false,
          lineId: false,
          subRegionId: false,
          territoryId: null,
          clientId: false,
          businessRule: false,
          family: false,
          productId: false,
        });
        setRequestParamsSubscription({
          ...requestParamsSubscription,
          productId: {
            ...requestParamsSubscription.productId,
            family: formValues.family,
            ...hasTerritoryId(),
          },
        });
        setAllowRequests({
          year: false,
          regionId: false,
          lineId: false,
          subRegionId: false,
          territoryId: null,
          clientId: false,
          businessRule: false,
          family: false,
          productId: true,
        });
        break;

      case 'productId':
        getProductInfo({
          params: {
            ...hasTerritoryId(),
            clientId: formValues.clientId,
            productId: formValues.productId,
            year: formValues.year,
            policyTypeId: formValues.businessRule,
          },
        });
        break;

      default:
        break;
    }

    const queryValues = form.getFieldsValue();
    saveQuery({ ...queryValues, state: 'changed' });
    onChangeActiveComponent('trade-agreements');
  };

  const commonmFiltersProps = {
    sourceId: 'Agreements',
  };

  const handleResetPage = useCallback(() => {
    cleanRegister();
    cleanTable();
    cleanViews();
    form.setFieldsValue({
      family: undefined,
      productId: undefined,
      businessRule: 3,
    });
  }, [cleanRegister, cleanTable, cleanViews, form]);

  const handleDaysLeft = useCallback(() => {
    if (agreementType && daysLeft) {
      switch (agreementType) {
        case 'GENERATE_TRADE_AGREEMENT':
          return daysLeft?.GENERATE_TRADE_AGREEMENT?.daysLeft;
        case 'TRADE_AGREEMENT_ADJUST':
          return daysLeft?.TRADE_AGREEMENT_ADJUST?.daysLeft;
        default:
          return '?';
      }
    }
  }, [agreementType, daysLeft]);

  const onModalOk = useCallback(
    (setShowModal) => {
      const { current } = ref;
      const values = current.getFieldsValue();

      const objectKeys = Object.keys(values);
      let products = [];
      let aux = [];

      const onSuccess = () => {
        setAddOkIsDisabled(false);
        form.setFieldsValue({
          [filtersNameEnum.FAMILY]: undefined,
          productId: undefined,
        });

        setDisabledFilters({
          zone: false,
          client: false,
          family: false,
          product: true,
        });

        setAllowRequests({
          zone: false,
          client: false,
          family: true,
          product: false,
        });

        familyReq({
          requestParams: { ...requestParamsSubscription?.['family'], ...hasTerritoryId() },
          sourceId: 'Agreements',
        });

        setShowModal(false);
        setAddOkIsDisabled(true);
        current.resetFields();
        current.setFieldsValue({ products: [0] });

        saveQuery({ ...form.getFieldsValue() });
        onChangeActiveComponent('trade-agreements');
        cleanSelectedProducts();
        setWasSelected(false);
      };

      if (objectKeys.length > 0) {
        products = values.products;

        if (products.length > 0) {
          products.filter((product, index) => {
            if (product[`product${index}`]) {
              aux = [...aux, product[`product${index}`]];
            }
            return null;
          });

          let params = {
            ...hasTerritoryId(),
            clientId: clientId,
            products: aux,
          };
          addProduct({ params, onSuccess, sourceId: 'Agreements' });
        }
      }
    },
    [
      cleanSelectedProducts,
      clientId,
      form,
      onChangeActiveComponent,
      saveQuery,
      addProduct,
      familyReq,
      hasTerritoryId,
      requestParamsSubscription,
    ]
  );

  const onModalCancel = useCallback(() => {
    const { current } = ref;
    current.resetFields();

    setAddOkIsDisabled(true);
    cleanSelectedProducts();
    setWasSelected(false);
    current.setFieldsValue({ products: [0] });
  }, [cleanSelectedProducts]);

  const onOneElementIsValid = () => {
    setAddOkIsDisabled(false);
  };

  useEffect(() => {
    form.setFieldsValue({ year: validityProcessYear });
  }, [form, validityProcessYear]);

  const setAutomaticallyAnOption = (options, optionKey = 1, filterName = 'none') => {
    if (options?.length > 0) {
      const optionIndex = options.findIndex((option) => option?.key === optionKey);
      form.setFieldsValue({
        [filterName]: options[optionIndex]?.key ?? undefined,
      });
      if (keyClient) {
        form.setFieldsValue({
          clientId: keyClient ?? undefined,
        });
      }
      const allValues = form.getFieldsValue();
      const changedValues = { [filterName]: options[optionIndex]?.key };

      handleOnFormValuesChange({ changedValues, allValues });
    }
  };

  //enviar territorio seleccionado en consultar acuerdo comercial  para el admin territoryId
  //para representantes de venta enviar null esto para terriotyId
  if (daysLeft && validityProcessYear) {
    return (
      <>
        <Row justify="end">
          <Col {...{ md: 5, sm: 24, lg: 4 }}>
            <div style={{ marginRight: '-80px' }}>
              <RemainingDays days={handleDaysLeft()} />
            </div>
          </Col>
        </Row>

        <Form
          style={{ marginTop: '-2.7rem' }}
          name="validate_other"
          onValuesChange={(changedValues, allValues) =>
            handleOnFormValuesChange({ changedValues, allValues })
          }
          form={form}
          initialValues={{
            territoryId: territoryId,
          }}
          layout="horizontal"
        >
          <Row gutter={{ xs: 8, sm: 8, md: 10, lg: 12 }}>
            <Col {...layoutFilters}>
              <Years {...commonmFiltersProps} askFromService={false} disabled />
            </Col>
            <Col {...layoutFilters}>
              <Clients
                {...commonmFiltersProps}
                allowClear={false}
                askFromService={allowRequest?.['clientId']}
                requestParams={{
                  year: validityProcessYear,
                  owner: true,
                  ...hasTerritoryId(),
                }}
                onSuccess={(options) => {
                  setIsNational(
                    !keyClient
                      ? false
                      : options.find((option) => option.key === keyClient)?.isNational
                  );
                  setAutomaticallyAnOption(options, consultClientKey, 'clientId');
                }}
                disabled={true}
              />
            </Col>
            {isNational && (
              <Col {...layoutFilters}>
                <Territory
                  {...commonmFiltersProps}
                  allowClear={false}
                  askFromService={allowRequest?.['territoryId']}
                  requestParams={requestParamsSubscription?.['territoryId']}
                  disabled={true}
                />
              </Col>
            )}

            <Col {...layoutFilters}>
              <BusinessRules
                {...commonmFiltersProps}
                allowClear={false}
                askFromService={allowRequest?.['businessRule']}
                requestParams={requestParamsSubscription?.['businessRule']}
                disabled={disabledFilters['businessRule']}
                onSuccess={(options) => setAutomaticallyAnOption(options, 3, 'businessRule')}
              />
            </Col>
            <Col {...layoutFilters}>
              <Family
                {...commonmFiltersProps}
                allowClear={false}
                askFromService={allowRequest?.['family']}
                requestParams={{ ...requestParamsSubscription?.['family'], ...hasTerritoryId() }}
                disabled={disabledFilters['family']}
              />
            </Col>
            <Col {...layoutFilters}>
              <Products
                {...commonmFiltersProps}
                allowClear={false}
                askFromService={allowRequest?.['productId']}
                requestParams={{ ...requestParamsSubscription?.['productId'], ...hasTerritoryId() }}
                disabled={disabledFilters['productId']}
                filterOption={(input, product) => {
                  let option = product.key.toLowerCase();
                  input = input.toLowerCase();
                  return option.indexOf(input) >= 0;
                }}
                optionKey="value"
                valueKey="key"
                showHasYearVolume={true}
                hasVolumeParams={{
                  clientId: consultClientKey ?? keyClient,
                  year: validityProcessYear,
                  ...hasTerritoryId(),
                }}
                ruleToClassName={productsHasAgreementVolume}
              />
            </Col>
            <Col {...{ md: 2, sm: 4, lg: 1 }}>
              <div style={{ marginTop: '36px', bottom: 0 }}>
                <ResetButton title="Limpiar filtros" onClick={handleResetPage} />
              </div>
            </Col>
            <Col style={{ marginTop: '36px' }}>
              {clientId ? (
                <ToggleModalAddProduct
                  tooltipText="Agregar otro producto"
                  conditionalClose
                  onOk={onModalOk}
                  onCancel={onModalCancel}
                >
                  <AddProductModal
                    onOneElementIsValid={onOneElementIsValid}
                    okIsDisabled={addOkIsDisabled}
                    okIsLoading={isAddingProduct}
                    selectedProduct={selectedProduct}
                    ref={ref}
                    wasSelected={wasSelected}
                    setShowAddAnotherOption={setWasSelected}
                    cleanProducts={cleanProducts}
                    sourceId={'Products'}
                    territoryId={territoryId}
                    hasTerritoryId={hasTerritoryId}
                    clientId={clientId}
                  />
                </ToggleModalAddProduct>
              ) : null}
            </Col>
          </Row>
        </Form>
      </>
    );
  } else {
    console.warn('Sé recargó la página:No se pudo leer información de territorio y daysLeft');

    return null;
  }
}

function mapStateToProps(state) {
  return {
    validityProcessYear:
      state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.year ?? false,
    daysLeft: state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data ?? false,
    territoryId: state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId ?? null,
    consultClientKey: state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.key ?? undefined,
    consultClientIsNational:
      state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.isNational ?? undefined,
    agreementType: state?.TRADE_AGREEMENTS?.infoForAgreement?.agreementType ?? false,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId ?? false,
    isAddingProduct: state?.TRADE_AGREEMENTS?.addProduct?.newProduct?.isPosting ?? false,
    tableIsValid: state?.TRADE_AGREEMENTS?.table?.tableIsValid,
    keyClient: state?.TRADE_AGREEMENTS?.clientKey?.clientKey ?? undefined,
  };
}

export default connect(mapStateToProps, {
  querySave,
  cleanRegisterTrade,
  cleanTopFilters,
  queryClean,
  getProductsRequest,
  cleanViewsRequest,
  cleanTableRequest,
  productSelectedClean,
  productsSelected,
  addProductRequest,
  familyRequest,
  validateTableRequest,
})(TopFiltersToRegister);
