import { fork, all } from 'redux-saga/effects';

import * as addProduct from './addProduct';
import * as filters from './filters';
import * as indicatorsSagas from './indicators';
import * as infoSagas from './info';
import * as registerTrade from './registerTrade';
import * as reportSagas from './reports';
import * as rules from './rules';
import * as table from './table';
import * as upload from './uploadTradeAgreements';
import * as agreement from './agreement';
import * as generateReport from './generateReport';
export default function* rootSaga() {
  yield all(
    [
      ...Object.values(addProduct),
      ...Object.values(filters),
      ...Object.values(indicatorsSagas),
      ...Object.values(infoSagas),
      ...Object.values(registerTrade),
      ...Object.values(reportSagas),
      ...Object.values(rules),
      ...Object.values(table),
      ...Object.values(upload),
      ...Object.values(agreement),
      ...Object.values(generateReport),
    ].map(fork)
  );
}
