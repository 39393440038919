import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import { Zones, Clients, Family, Products, Years } from 'RootModule/catalogs';
import { connect } from 'react-redux';
import {
  getProductsRequest,
  getChallengePogRequest,
  cleanRegisterSale,
} from 'SalesChallengeRefactor/redux/actions/registerSale';
import { querySave } from 'SalesChallengeRefactor/redux/actions/query';
import { reachRequest, cleanChallenge } from 'SalesChallengeRefactor/redux/actions/challenge';
import { salesGoalRequest, graphRequest } from 'SalesChallengeRefactor/redux/actions/graph';
import { cleanTable, validateTableRequest } from 'SalesChallengeRefactor/redux/actions/table';
import { openNotification } from 'common';
import { MSG_003, MSG_019_020 } from 'SalesChallengeRefactor/utils/messages';
import { addMessageValue } from 'SalesChallengeRefactor/redux/actions/messages';
import { useHistory } from 'react-router-dom';
import { salesChallengeStatus } from 'SalesChallengeRefactor/models/enums/';
import ToggleModalAddProduct from 'common/components/BToggleModalControl';
import {
  addProductRequest,
  productsSelected,
  productSelectedClean,
} from 'SalesChallengeRefactor/redux/actions/addProduct';
import { getProductsAddClean, familyRequest } from 'RootModule/redux/actions/catalogs';
import AddProductModal from 'common/components/AddProductModal';

const layoutFilters = {
  xs: 24,
  sm: 12,
  md: 6,
  lg: 5,
  xlg: 5,
};
const { ON_PROCESS, REJECTED, NULL } = salesChallengeStatus;
const clientIsEnable = [ON_PROCESS, REJECTED, NULL];
function TopFiltersGroupRegister(props) {
  const {
    query,
    tableIsValid,
    intialValues,
    getProductsRequest: getProductsInfoReq,
    getChallengePogRequest: getChallengePog,
    addProductRequest: addProduct,
    cleanAddProducts: cleanProducts,
    querySave: saveQuery,
    reachRequest: reachReq,
    salesGoalRequest: salesGoalReq,
    cleanTable: resetTable,
    onChangeActiveComponent,
    cleanChallenge: resetChallenge,
    cleanRegisterSale: resetRegisterSale,
    consultZone,
    validateTableRequest: validateTableMandatory,
    addMessageValue: saveMessageLabel,
    pageType,
    isAddingProduct,
    getProductsAddClean: cleanProductFilters,
    productsSelected: selectedProduct,
    productSelectedClean: cleanSelectedProducts,
    familyRequest: familyReq,
  } = props;
  const [wasSelected, setWasSelected] = useState(false);

  const [form] = Form.useForm();
  const ref = useRef();

  const [disabledFilters, setDisabledFilters] = useState({
    zone: false,
    client: true,
    family: true,
    product: true,
  });

  const [allowRequest, setAllowRequests] = useState({
    zone: true,
    client: false,
    family: false,
    product: false,
  });

  const [subscriptions, setSubcriptions] = useState({
    clientId: {},
    family: {},
    product: {},
    addProduct: {},
  });

  const [addOkIsDisabled, setAddOkIsDisabled] = useState(true);

  useEffect(() => {
    if (consultZone) {
      form.setFieldsValue({ zone: consultZone ?? '' });
      //Permite cargar al cliente
      setAllowRequests({ zone: false, client: true, family: false, product: false });
      //Permite seleccionar la siguiente opcion
      setDisabledFilters({ client: false, family: true, product: true });
      // Informa a los siguientes filtros que zona se selecciono
      setSubcriptions({
        client: { zoneId: consultZone },
        family: { zoneId: consultZone },
        product: { zoneId: consultZone },
        addProduct: { zoneId: consultZone },
      });
      //Va por la zona
      salesGoalReq({ params: { zoneId: consultZone } });
    }
  }, [consultZone, form, salesGoalReq]);

  const filtersNameEnum = {
    YEARS: 'years',
    ZONE: 'zone',
    CLIENT: 'client',
    FAMILY: 'family',
    PRODUCT: 'product',
  };

  const handleOnValuesChange = ({ changedValues, allValues }) => {
    if (typeof tableIsValid.state === 'boolean' && !tableIsValid.state && !tableIsValid.wasSend) {
      setTimeout(() => {
        openNotification('error', MSG_003);
        validateTableMandatory({ state: 'initial', wasSend: true });
      }, 300);
    }

    const changedValueKey = Object.keys(changedValues)[0];
    const { ZONE, CLIENT, FAMILY, PRODUCT, YEARS } = filtersNameEnum;
    const { setFieldsValue } = form;

    switch (changedValueKey) {
      case ZONE:
        setDisabledFilters({ client: false, family: true, product: true });
        setFieldsValue({ client: undefined, family: undefined, product: undefined });
        setAllowRequests({ zone: false, client: true, family: false, product: false });
        setSubcriptions({
          ...subscriptions,
          client: { ...subscriptions.client, zoneId: allValues[ZONE] },
          family: { ...subscriptions.family, zoneId: allValues[ZONE] },
          product: { ...subscriptions.product, zoneId: allValues[ZONE] },
          addProduct: { ...subscriptions.addProduct, zoneId: allValues[ZONE] },
        });
        salesGoalReq({ params: { zoneId: allValues[ZONE] } });
        break;

      case CLIENT:
        resetChallenge();
        resetRegisterSale();
        setDisabledFilters({ client: false, family: false, product: true });
        setFieldsValue({ family: undefined, product: undefined });
        setAllowRequests({ zone: false, client: false, family: true, product: false });
        setSubcriptions({
          ...subscriptions,
          family: { ...subscriptions.family, clientId: allValues[CLIENT] },
          product: { ...subscriptions.product, clientId: allValues[CLIENT] },
          addProduct: { ...subscriptions.addProduct, clientId: allValues[CLIENT] },
        });
        reachReq({ params: { zoneId: allValues[ZONE], clientId: allValues[CLIENT] } });
        break;

      case FAMILY:
        //limpiar store
        resetRegisterSale();
        setDisabledFilters({ client: false, family: false, product: false });
        setFieldsValue({ product: undefined });
        setAllowRequests({ zone: false, client: false, family: false, product: true });
        setSubcriptions({
          ...subscriptions,
          product: { ...subscriptions.product, family: allValues[FAMILY] },
          addProduct: { ...subscriptions.addProduct, family: allValues[FAMILY] },
        });
        reachReq({ params: { zoneId: allValues[ZONE], clientId: allValues[CLIENT] } });
        break;

      case PRODUCT:
        setSubcriptions({
          ...subscriptions,
          addProduct: { ...subscriptions.addProduct, product: allValues[PRODUCT] },
        });

        resetRegisterSale();

        getProductsInfoReq({
          params: {
            zoneId: allValues[ZONE],
            clientId: allValues[CLIENT],
            productId: allValues[PRODUCT],
          },
        });
        getChallengePog({
          params: {
            zoneId: allValues[ZONE],
            clientId: allValues[CLIENT],
            productId: allValues[PRODUCT],
            year: allValues[YEARS],
          },
          origin: 'filter',
        });
        reachReq({ params: { zoneId: allValues[ZONE], clientId: allValues[CLIENT] } });

        break;

      default:
        console.warn('[Agroplan] No actions for this filter >>', changedValueKey);
        break;
    }
    //Sahring this part of the query with other components
    saveQuery({ ...form.getFieldsValue() });
    //Reseting the table
    resetTable();
    onChangeActiveComponent('sales-challenge');
  };

  const handleOnYearsOptionsLoad = (yearsOptions) => {
    form.setFieldsValue({ years: yearsOptions?.year });
  };

  const commonFilterProps = {
    allowClear: false,
  };

  const disabledClients = (el) => {
    if (pageType === 'adjusted') {
      return { disabled: !clientIsEnable.includes(el.challengeStatusId) };
    }
    return {};
  };

  const clientsApproved = (el) => {
    if (el) {
      return {
        className: el.status ? 'element-registered' : 'not-special',
        ...disabledClients(el),
      };
    }
  };

  const productsApproved = (el) => {
    if (el) {
      return {
        className: el.status ? 'element-registered' : 'not-special',
      };
    }
  };
  const history = useHistory();

  const handleProductOnSuccess = useCallback(
    (data) => {
      if (data.length === 0) {
        openNotification('error', MSG_019_020('Desafío de Ventas', 'productos'));
        setTimeout(() => history.goBack(), 1500);
      }
    },
    [history]
  );

  const handleOnClientsSuccess = useCallback(
    (data) => {
      if (data.length === 0) {
        openNotification('error', MSG_019_020('Desafío de Ventas', 'clientes'));
        setTimeout(() => history.goBack(), 1500);
      }
    },
    [history]
  );

  const onModalOk = (setShowModal) => {
    const { current } = ref;
    const values = current.getFieldsValue();

    const objectKeys = Object.keys(values);
    let products = [];
    let aux = [];

    const onSuccess = () => {
      setAddOkIsDisabled(false);
      form.setFieldsValue({
        [filtersNameEnum.FAMILY]: undefined,
        [filtersNameEnum.PRODUCT]: undefined,
      });

      const allValues = form.getFieldsValue();
      setDisabledFilters({
        zone: false,
        client: false,
        family: false,
        product: true,
      });

      setAllowRequests({
        zone: false,
        client: false,
        family: true,
        product: false,
      });

      //TODO MOVE THIS TO ONSUCCESS EXECUTION OF SAGA
      familyReq({ requestParams: subscriptions?.family, sourceId: 'Sales' });

      setSubcriptions({
        ...subscriptions,
        product: { zoneId: consultZone, clientId: allValues[filtersNameEnum.CLIENT] },
        addProduct: { zoneId: consultZone, clientId: allValues[filtersNameEnum.CLIENT] },
      });

      setShowModal(false);
      setAddOkIsDisabled(true);
      current.resetFields();
      current.setFieldsValue({ products: [0] });
      resetTable();
      saveQuery({ ...form.getFieldsValue() });
      onChangeActiveComponent('sales-challenge');
      resetRegisterSale();
      reachReq({ params: { zoneId: query.zoneId, clientId: query.client } });
    };

    if (objectKeys.length > 0) {
      products = values.products;

      if (products.length > 0) {
        products.filter((product, index) => {
          if (product[`product${index}`]) {
            aux = [...aux, product[`product${index}`]];
          }
          return null;
        });

        let params = {
          zoneId: consultZone,
          clientId: subscriptions?.addProduct?.clientId,
          products: aux,
        };
        addProduct({ params, onSuccess });
        cleanSelectedProducts();
        setWasSelected(false);
        reachReq({ params: { zoneId: query.zoneId, clientId: query.client } });
      }
    }
  };

  const onModalCancel = () => {
    const { current } = ref;
    current.resetFields();
    cleanProductFilters();
    setAddOkIsDisabled(true);
    cleanSelectedProducts();
    setWasSelected(false);
    current.setFieldsValue({ products: [0] });
  };

  const onOneElementIsValid = () => {
    setAddOkIsDisabled(false);
  };

  return (
    <>
      <Form
        form={form}
        initialValues={intialValues}
        onValuesChange={(changedValues, allValues) =>
          handleOnValuesChange({ changedValues, allValues })
        }
      >
        <Row justify="space-between">
          <Col {...{ md: 3, sm: 12, xs: 24 }}>
            <Years
              sourceId="DaysLeft"
              name={filtersNameEnum.YEARS}
              label="Año"
              disabled
              onOptionsChange={handleOnYearsOptionsLoad}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Zones
              label="Zona"
              name={filtersNameEnum.ZONE}
              askFromService={allowRequest.zone}
              disabled
            />
          </Col>
          <Col {...layoutFilters}>
            <Clients
              label="Cliente"
              disabled={disabledFilters.client}
              name={filtersNameEnum.CLIENT}
              askFromService={allowRequest.client}
              sourceId="Sales"
              ruleToClassName={clientsApproved}
              requestParams={subscriptions.client}
              pageType="register"
              onSuccess={handleOnClientsSuccess}
              onChange={(x, option) => saveMessageLabel({ clientLabel: option?.children })}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Family
              label="Familia"
              disabled={disabledFilters.family}
              name={filtersNameEnum.FAMILY}
              askFromService={allowRequest.family}
              sourceId="Sales"
              requestParams={subscriptions.family}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Products
              label="Producto"
              disabled={disabledFilters.product}
              name={filtersNameEnum.PRODUCT}
              askFromService={allowRequest.product}
              sourceId="Sales"
              ruleToClassName={productsApproved}
              requestParams={subscriptions.product}
              pageType="register"
              onChange={(x, option) => saveMessageLabel({ productLabel: option?.children })}
              onSuccess={handleProductOnSuccess}
              {...commonFilterProps}
            />
          </Col>
          <Col style={{ marginTop: '36px' }}>
            {subscriptions?.addProduct.clientId ? (
              <ToggleModalAddProduct
                tooltipText="Agregar otro producto"
                conditionalClose
                onOk={onModalOk}
                onCancel={onModalCancel}
              >
                <AddProductModal
                  onOneElementIsValid={onOneElementIsValid}
                  okIsDisabled={addOkIsDisabled}
                  okIsLoading={isAddingProduct}
                  selectedProduct={selectedProduct}
                  ref={ref}
                  wasSelected={wasSelected}
                  setShowAddAnotherOption={setWasSelected}
                  cleanProducts={cleanProducts}
                  zoneId={consultZone}
                  territoryId={subscriptions?.addProduct?.territory}
                  clientId={subscriptions?.addProduct?.clientId}
                />
              </ToggleModalAddProduct>
            ) : null}
          </Col>
        </Row>
      </Form>
    </>
  );
}

TopFiltersGroupRegister.propTypes = {
  intialValues: PropTypes.object,
};

TopFiltersGroupRegister.defaultProps = {
  intialValues: {},
};

function mapStateToProps(state) {
  return {
    consultZone: state?.SALES_CHALLENGE?.subscriptions?.register?.zoneId ?? false,
    itCanSave: state?.SALES_CHALLENGE?.registerSales?.volumesValidsToSave ?? false,
    tableIsValid: state?.SALES_CHALLENGE?.table?.tableIsValid,
    isAddingProduct: state?.SALES_CHALLENGE?.addProduct?.newProduct?.isPosting ?? false,
    query: state?.SALES_CHALLENGE?.query,
  };
}

export default connect(mapStateToProps, {
  getProductsRequest,
  querySave,
  getChallengePogRequest,
  reachRequest,
  salesGoalRequest,
  graphRequest,
  cleanTable,
  cleanChallenge,
  cleanRegisterSale,
  validateTableRequest,
  addMessageValue,
  addProductRequest,
  getProductsAddClean,
  productsSelected,
  productSelectedClean,
  familyRequest,
})(TopFiltersGroupRegister);
