import request from '../../services/request';
const URL_API = '/Agreements/Api/v1/Products/';
const URL_AGREEMENTS = '/Agreements/Api/v1/Agreements/';

export async function getProducts(params, cancelToken) {
  return request(`${URL_API}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getProductsPrices(params, cancelToken) {
  return request(`${URL_API}Prices/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function addProduct({ params, cancelToken }) {
  return request(`${URL_API}Add`, {
    method: 'POST',
    cancelToken,
    data: params,
  });
}

export async function getAgreement({ params, cancelToken }) {
  return request(`${URL_AGREEMENTS}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getReach({ params, cancelToken }) {
  return request(`${URL_AGREEMENTS}Reach`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function postAgreements({ data, cancelToken }) {
  return request(`${URL_AGREEMENTS}`, {
    method: 'POST',
    data,
    cancelToken,
  });
}

export async function sendTovalidate({ params, cancelToken }) {
  return request(`${URL_AGREEMENTS}SendToValidate`, {
    method: 'PUT',
    data: params,
    cancelToken,
  });
}

export async function getAgreementsComments({ params, cancelToken }) {
  return request(`${URL_AGREEMENTS}AgreementComments`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
