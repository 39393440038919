import React from 'react';
import { Modal } from 'antd';

const actionButtonsStyle = {
  margin: '16px',
  marginTop: '30px',
  color: 'white',
  borderRadius: '5px',
  width: '220px',
  height: '35px',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

function summaryEndModal(response, link, url) {
  const onOkButton = (history, urlToRedirect) => {
    history.push(urlToRedirect);
  };
  Modal.info({
    title: 'Resumen de registros realizados',
    content: (
      <div>
        <p>
          Se realizaron {response.correct} registros exitosamente y se rechazaron{' '}
          {response.totalErrors} registros
        </p>
      </div>
    ),
    okText: 'Aceptar',
    onOk() {
      onOkButton(link, url);
    },
    okButtonProps: { size: 'middle', type: 'primary', style: actionButtonsStyle },
  });
}
export default summaryEndModal;
