import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BSelect } from 'common/components';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';
import { businessLineRequest } from 'RootModule/redux/actions/catalogs';

function BusinessLine(props) {
  const {
    businessLines,
    businessLineRequest: getBusinessLines,
    requestParams,
    sourceId,
    name,
    label,
    askFromService,
    onOptionsChange,
    onSuccess,
    onChange,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) {
      getBusinessLines({ requestParams, sourceId, onSuccess });
    }
    // eslint-disable-next-line
  }, [getBusinessLines, requestParams, askFromService, sourceId]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(businessLines.data ?? []);
    }
  }, [businessLines, onOptionsChange]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: businessLines?.data, name: props.name });
  };

  return (
    <BSelect
      isLoading={businessLines.isLoading}
      disabled={businessLines.isLoading}
      name={name}
      options={businessLines.data}
      label={label}
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={handleOnChange}
      {...other}
    />
  );
}

BusinessLine.propTypes = {
  initialValue: PropTypes.any,
  businessLineRequest: PropTypes.func,
  businessLines: PropTypes.object,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Sales', 'Catalogs']),
  askFromService: PropTypes.bool,
  onOptionsChange: PropTypes.func,
};

BusinessLine.defaultProps = {
  initialValue: undefined,
  businessLineRequest: null,
  businessLines: { data: [], isLoading: false },
  label: filtersEnum.businessLine.label,
  name: filtersEnum.businessLine.name,
  requestParams: {},
  sourceId: 'Sales',
  askFromService: true,
  onOptionsChange: null,
};

function mapStateToProps(state) {
  return {
    businessLines: state.APP?.catalogs?.businessLines,
  };
}

export default connect(mapStateToProps, { businessLineRequest })(BusinessLine);
