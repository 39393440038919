import Dashboard from '../../Dashboard';
import DashboardLayout from '../../Dashboards/dashboardLayout';
import { catalogModulesMap } from './catalogModulesMap';
import { accountStatusModules } from './accountStatusModules';
import { historicInformation } from './historicInformation.map';
import SalesChallengeRegister from '../../SalesChallengeRefactor/pages/SalesChallengeRegister';
import TradeAgreements from 'TradeAgreements/pages/TradeAgreements';
import TradeAgreementsRegister from '../../TradeAgreements/pages/TradeAgreementsRegister';
import TradeAgreementsGenerate from 'TradeAgreements/pages/TradeAgreementsGenerate';
import ConsultLog from '../../ConsultLog/pages';
import SalesChallenge from '../../SalesChallengeRefactor';
import MasiveExchangeRateUpload from '../../MasiveExchangeRateUpload/pages';
import { USERS_MAP } from './usersModules.map';
import Notifications from 'Notifications/pages';
import Maps from 'Dashboards/Maps/containers/Maps';
import SalesChallengeDashboard from 'Dashboards/SalesChallenge/salesChallenge';
import Comparative from 'Dashboards/Comparative/containers/Comparative';
import { CommercialAgreementComponent } from '../../Dashboards/CommercialAgreement/CommercialAgreementComponent';
const RootModulesMap = {
  dashboard: Dashboard,
  dashboards: DashboardLayout,
  ...USERS_MAP,
  'challenge-dashboard': SalesChallengeDashboard,
  'commercial-agreement-dashboard': CommercialAgreementComponent,
  'map-dashboard': Maps,
  'comparative-dashboard': Comparative,
  'trade-agreements': TradeAgreements,
  'sales-challenge': SalesChallenge,
  'register_trade-agreements': TradeAgreementsRegister,
  'update_trade-agreements': TradeAgreementsRegister,
  'adjusted_trade-agreements': TradeAgreementsRegister,
  'register_sales-challenge': SalesChallengeRegister,
  'adjusted_sales-challenge': SalesChallengeRegister,
  'generate_trade-agreements': TradeAgreementsGenerate,
  'update_sales-challenge': SalesChallengeRegister,
  binnacle: ConsultLog,
  'upload_exchange-rate': MasiveExchangeRateUpload,
  notifications: Notifications,
  ...catalogModulesMap,
  ...historicInformation,
  ...accountStatusModules,
};
export default RootModulesMap;
