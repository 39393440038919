import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import DownloadButton from '../../../common/DownloadButton';
import { reportRequest } from '../../../redux/actions/reports';
import { openNotification } from '../.../../../../../common/misc/openNotification';

function DownloadReport(props) {
  const { reportRequest: generateReport, current, report, query } = props;

  const handleGenerateReport = useCallback(() => {
    let reportName = '';
    let date = new Date().toISOString().split('T')[0];
    const {
      regionId,
      lineId,
      subRegionId,
      family,
      clientId,
      productId,
      territoryId,
      businessRule: policyTypeId,
      year,
    } = query;
    let params = { year, name: `ACUERDO ${date}`, lineId };

    switch (current) {
      case 'regionId':
        reportName = 'region';
        params = { ...params, regionId };
        break;
      case 'lineId':
        reportName = 'businessLine';
        params = { ...params, regionId, lineId };
        break;
      case 'subRegionId':
        reportName = 'subRegion';
        params = { ...params, regionId, clientId, subRegionId };
        break;
      case 'territoryId':
        reportName = 'territory';
        params = { ...params, regionId, territoryId };
        break;
      case 'family':
        reportName = 'family';
        params = { ...params, clientId, family, territoryId, policyTypeId };
        break;
      case 'clientId':
        reportName = 'client';
        params = { ...params, clientId, territoryId };
        break;
      case 'productId':
        reportName = 'product';
        params = { ...params, clientId, productId, territoryId, policyTypeId };
        break;

      default:
        break;
    }

    generateReport({
      sourceId: reportName,
      params,
      onSuccess: () => openNotification('success', 'Reporte generado correctamente'),
    });
  }, [query, current, generateReport]);

  if (query && query.year && Object.keys(query).length > 1) {
    return (
      <DownloadButton
        onClick={handleGenerateReport}
        loading={report?.isDownloading}
        label={report?.isDownloading ? 'Generando reporte' : 'Generar reporte'}
      />
    );
  } else {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    current: state?.TRADE_AGREEMENTS?.views?.current,
    report: state?.TRADE_AGREEMENTS?.report,
    query: state?.TRADE_AGREEMENTS?.query,
  };
}

export default connect(mapStateToProps, { reportRequest })(DownloadReport);
