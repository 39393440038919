import { takeLatest, cancelled, put, call, select, delay } from 'redux-saga/effects';
import {
  SAVE_VOLUME_REQUEST,
  saveVolumeFail,
  saveVolumeSuccess,
  SAVE_UPDATE_PRICES,
  validateTableRequest,
} from '../actions/table';
import { responseOK } from 'utils';
import { postChallenge } from 'SalesChallengeRefactor/services/registerSales';
import instance from '../../../services/request';
import { months, monthsEnum, whoSaveChallenge } from 'SalesChallengeRefactor/models/enums';
import {
  areVolumesValids,
  saveChallengeFail,
  saveChallengeSuccess,
  saveChallengeRequest,
} from '../actions/registerSale';
import { salesGoalSuccess } from '../actions/graph';
import { reachSuccess } from '../actions/challenge';
import { openNotification } from '../../../common';
import { calculateMoq } from 'SalesChallengeRefactor/helpers/helperEditable';
import { MSG_035, MSG_003 } from '../../utils/messages';
import { orderArrayObjects } from 'SalesChallengeRefactor/utils';
import { updateClients, updateProducts } from 'RootModule/redux/actions/catalogs';

const { SAVE_BUTTON, CELL, ON_CHANGE_PRICES } = whoSaveChallenge;

const isMultiplyMoq = (v, moq) => {
  return calculateMoq(v, moq);
};

const sumValues = (data, price, moq) => {
  let sum = {
    ene: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    ago: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dic: 0,
    total: 0,
  };

  if (data.length > 0) {
    data.map((row) => {
      let rowKeys = Object.keys(row);

      if (rowKeys.length > 0) {
        rowKeys.map((key) => {
          if (months[key] === key) {
            if (typeof row[key] === 'number' && !isNaN(row[key])) {
              let isMoq = isMultiplyMoq(row[key], moq);
              if (isMoq) {
                sum[key] += row[key] * price;

                sum['total'] += sum[key];
              }
            }
          }
          return null;
        });
      }
      return null;
    });

    return sum;
  }
};

export const cleanValuesToSum = (
  newData,
  updateRow,
  price,
  moq,
  data,
  keyExcludeSum,
  productInfo
) => {
  let auxCleanData = [];
  let update = {};

  if (newData.length > 0) {
    newData.map((row) => {
      let auxRowMonths = {};
      let auxRestKeys = {};

      let fullRow = {};
      let rowKeys = Object.keys(row);
      if (rowKeys.length > 0) {
        rowKeys.map((month) => {
          /* typeof row[month] === 'string' ? formatPriceToNumber(row[month]) :*/
          if (months[month]) {
            auxRowMonths = {
              ...auxRowMonths,
              [month]: row[month],
            };
          } else {
            auxRestKeys = {
              ...auxRestKeys,
              [month]: row[month],
            };
          }
          fullRow = { ...auxRowMonths, ...auxRestKeys };
          return null;
        });

        auxCleanData = [...auxCleanData, fullRow];
      }

      let updateKeys = Object.keys(updateRow);
      if (updateKeys && updateKeys.length > 0) {
        let auxMonths = {};
        let auxRest = {};
        let complete = {};
        let totalVolume = 0;

        updateKeys.map((key) => {
          if (months[key]) {
            auxMonths = {
              ...auxMonths,
              [key]: updateRow[key],
            };
          } else {
            auxRest = {
              ...auxRest,
              [key]: updateRow[key],
            };
          }

          complete = { ...auxMonths, ...auxRest };
          return null;
        });
        let completeKeys = Object.keys(complete);

        if (completeKeys.length > 0) {
          completeKeys.map((key) => {
            if (months[key]) {
              if (typeof complete[key] === 'number') {
                let isMoq = isMultiplyMoq(complete[key], moq);

                if (isMoq) {
                  totalVolume += complete[key];
                }
              }
            }
            return null;
          });
        }

        update = { ...complete, total: totalVolume };
      }

      return null;
    });

    let allData = [];

    if (keyExcludeSum === 7) {
      if (Object.keys(update).length > 0) {
        allData = [...auxCleanData, update];
      } else {
        allData = [...auxCleanData];
      }

      let restRows = allData.filter((row) => row.rowKey === 7);

      //sólo calculamos sobre el rowKey 7
      let total = sumValues(restRows, price, moq);

      return { total, allData };
    } else {
      try {
        let excludeVolumes = auxCleanData.filter((row) => row.rowKey !== 1);

        allData = [...excludeVolumes, update];

        //realizar operacion por bruto y neto

        let totalNet = sumValues([update], productInfo.netPrice, moq);
        let totalBrute = sumValues([update], productInfo.brutePrice, moq);

        let rowNet = allData.find((row) => row.rowKey === 2);
        rowNet = { ...rowNet, ...totalNet };

        let rowBrute = allData.find((row) => row.rowKey === 3);
        rowBrute = { ...rowBrute, ...totalBrute };

        let mergeTotal = [rowBrute, rowNet];
        let volume = allData.filter((row) => row.rowKey === 1);

        allData = [...volume, ...mergeTotal];

        return { allData };
      } catch (error) {
        console.error('error :>> ', error);
      }
    }
  }
};

export const processData = (currentTable, data, productInfo, keyExcludeSums) => {
  //menor que 9 es cuándo editamos una celda
  let excludeKeySum = keyExcludeSums ? keyExcludeSums : 7;
  if (currentTable && currentTable.length > 0 && Object.keys(data).length < 9) {
    let excludeRowKeys = [8, 7];
    let updateRow = {};

    let excludeRows = currentTable.filter((row) => !excludeRowKeys.includes(row.rowKey));
    let currentVolume = currentTable.filter((row) => row.rowKey === 7);
    let currentTotal = currentTable.filter((row) => row.rowKey === 8);
    if (typeof data !== 'undefined' && data.key) {
      updateRow = { ...currentVolume[0], [data.key]: data.value };
    } else {
      updateRow = { ...currentVolume[0] };
    }

    let newData = [...excludeRows];

    try {
      if (productInfo && typeof productInfo?.netPrice === 'number') {
        let totals = cleanValuesToSum(
          newData,
          updateRow,
          productInfo.netPrice,
          productInfo.moq,
          data,
          excludeKeySum,
          productInfo
        );

        if (totals && Object.keys(totals).length > 0) {
          let mergeTotal = { ...currentTotal[0], ...totals.total };

          return [...totals.allData, mergeTotal];
        }
      } else {
        console.warn('OJO: No se recibio el precio como número');
      }
    } catch (error) {
      console.warn('error :>> ', error);
      alert('Ocurrio un error en el calculo ');
    }
  }
};

export const checkIfVolumesAreValids = (data, moq) => {
  let aux = [];

  let rowKeys = Object.keys(data);
  if (rowKeys.length > 0) {
    rowKeys.map((key) => {
      if (months[key]) {
        if (isMultiplyMoq(data[key], moq) && typeof data[key] === 'number') {
          aux = [...aux, { volume: data[key], month: monthsEnum[key] }];
        } else {
          return false;
        }
      }
      return null;
    });
    return aux;
  }
};

const howToSaveVolume = (query, productInfo, whoSave, data, totalValids) => {
  if (whoSave) {
    let paramsToSave = {
      zoneId: query.zone,
      clientId: query.client,
      priceId: productInfo.priceId,
      productId: query.product,
      volumes: [],
      // volumes: [{ month: monthsEnum[data.key], volume: data.value }],
    };
    switch (whoSave) {
      case CELL:
        paramsToSave.volumes = totalValids;
        break;
      case SAVE_BUTTON:
        if (totalValids.length === 12) {
          paramsToSave.volumes = totalValids;
        } else {
          openNotification('error', MSG_003);
          openNotification('error', MSG_035());
        }

        break;
      case ON_CHANGE_PRICES:
        break;

      default:
        break;
    }
    return paramsToSave;
  }
};

function* workSaveUpdatePrices(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { netPrice } = payload;
    const currentTable = yield select((state) => state?.SALES_CHALLENGE?.table?.data);

    const productInfo = yield select(
      (state) => state?.SALES_CHALLENGE?.registerSales?.productPrices?.data ?? false
    );
    let restRows = currentTable.filter((row) => row.rowKey !== 8);
    let volumes = currentTable.filter((row) => row.rowKey === 7);
    const query = yield select((state) => state?.SALES_CHALLENGE?.query);
    let newTotal = sumValues(volumes, netPrice, productInfo.moq);
    let currentTotal = currentTable.filter((row) => row.rowKey === 8);

    newTotal = { ...currentTotal[0], ...newTotal };

    let replaceData = [...restRows, newTotal];

    if (typeof replaceData[0].moq !== 'undefined') {
      let rowVolumes = replaceData.find((row) => row.rowKey === 7);
      if (rowVolumes) {
        yield put(saveChallengeRequest());
        let totalValids = checkIfVolumesAreValids(rowVolumes, productInfo.moq);

        if (totalValids.length > 0) {
          //  yield put(validateTableRequest({ state: totalValids.length === 12 }));

          let paramsToSave = {
            zoneId: query.zone,
            clientId: query.client,
            priceId: productInfo.priceId,
            productId: query.product,
            volumes: totalValids,
          };
          const response = yield call(postChallenge, {
            cancelToken: source.token,
            data: paramsToSave,
          });
          if (responseOK(response)) {
            yield put(salesGoalSuccess(response.data.goalCoverage));
            yield put(reachSuccess(response.data.reach));
          }
        }
        yield put(saveChallengeSuccess());
        yield put(saveVolumeSuccess(replaceData));
      }
    }
    // if (onSuccess) {
    //   yield onSuccess();
    // }
  } catch (_e) {
    console.error(_e);
    yield put(saveVolumeFail());
    yield put(saveChallengeFail());
    yield put(saveChallengeSuccess());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* workSaveVolumeRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { data, onSuccess } = payload;
    const currentTable = yield select((state) => state?.SALES_CHALLENGE?.table?.data);
    const query = yield select((state) => state?.SALES_CHALLENGE?.query);
    const productInfo = yield select(
      (state) => state?.SALES_CHALLENGE?.registerSales?.productPrices?.data ?? false
    );
    let sum = false;

    if (data.whoSaveChallenge !== CELL) {
      sum = yield processData(currentTable, {}, productInfo);
    } else {
      sum = yield processData(currentTable, data, productInfo);
    }

    if (sum) {
      let rowVolumes = sum.find((row) => row.rowKey === 7);
      if (rowVolumes) {
        let totalValids = checkIfVolumesAreValids(rowVolumes, productInfo.moq);
        let hasAllMonths = totalValids.length === 12;
        let currentClient = query.client;
        let currentProduct = query.product;

        if (hasAllMonths) {
          const currentClients = yield select((state) => state?.APP?.catalogs?.clients?.data);
          const currentProducts = yield select((state) => state?.APP?.catalogs?.products?.data);
          let found = currentClients.find((client) => client.key === currentClient);
          let rest = currentClients.filter((client) => client.key !== currentClient);
          let foundProduct = currentProducts.find((product) => product.key === currentProduct);
          let restProducts = currentProducts.filter((product) => product.key !== currentProduct);
          if (found) {
            let updateClient = { ...found, status: true };
            let newClients = [...rest, updateClient];
            let orderClients = newClients.sort(orderArrayObjects('place'));
            yield put(updateClients(orderClients));
          }

          if (foundProduct) {
            let updateProduct = { ...foundProduct, status: true };
            let newProducts = [...restProducts, updateProduct];
            let orderProducts = newProducts.sort(orderArrayObjects('place'));

            yield put(updateProducts(orderProducts));
          }
        }

        yield put(areVolumesValids(hasAllMonths));

        yield put(validateTableRequest({ state: hasAllMonths, wasSend: false }));

        let orderTable = sum.sort(orderArrayObjects('rowKey'));

        yield put(saveVolumeSuccess(orderTable));

        if (data.isValid) {
          yield put(saveChallengeRequest());
          let dataToSave = yield howToSaveVolume(
            query,
            productInfo,
            data.whoSaveChallenge,
            data,
            totalValids
          );

          if (dataToSave && dataToSave.volumes.length > 0) {
            yield put(areVolumesValids(dataToSave.volumes.length === 12));

            yield put(
              validateTableRequest({ state: dataToSave.volumes.length === 12, wasSend: false })
            );

            let hasValue = typeof data.value === 'number';
            if (hasValue) {
              const response = yield call(postChallenge, {
                cancelToken: source.token,
                data: dataToSave,
              });
              if (responseOK(response)) {
                if (response.data.goalCoverage)
                  yield put(salesGoalSuccess(response.data.goalCoverage));
                if (response.data.reach) yield put(reachSuccess(response.data.reach));
              }
            }
          }
          yield put(saveChallengeSuccess());
        } else if (data.whoSaveChallenge === 'SAVE_BUTTON') {
          yield put(saveChallengeRequest());

          if (totalValids.length === 12) {
            let paramsToSave = {
              zoneId: query.zone,
              clientId: query.client,
              priceId: productInfo.priceId,
              productId: query.product,
              volumes: totalValids,
              // volumes: [{ month: monthsEnum[data.key], volume: data.value }],
            };
            const response = yield call(postChallenge, {
              cancelToken: source.token,
              data: paramsToSave,
            });
            if (responseOK(response)) {
              if (response.data.goalCoverage)
                yield put(salesGoalSuccess(response.data.goalCoverage));
              if (response.data.reach) yield put(reachSuccess(response.data.reach));

              if (onSuccess) {
                if (payload?.message) {
                  yield call(openNotification, 'success', payload?.message);
                  yield delay(800);
                  yield onSuccess();
                }
              }
            }
          }
          yield put(saveChallengeSuccess());
        }
        //
      } else {
        yield put(saveVolumeSuccess(sum));
      }
    }
  } catch (_e) {
    console.error(_e);
    yield put(saveVolumeFail());
    yield put(saveChallengeFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchSaveVolumeRequest() {
  yield takeLatest(SAVE_VOLUME_REQUEST, workSaveVolumeRequest);
}

export function* watchSaveUpdatePrices() {
  yield takeLatest(SAVE_UPDATE_PRICES, workSaveUpdatePrices);
}
