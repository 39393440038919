import React from 'react';
import Table from './table';
import BSFiltersRow from './bsFiltersRow';

function Archive({
  onFilterHeaderChange,
  columns,
  searchInput,
  beFilters,
  selectors,
  dateRange,
  multipeYears,
  columnSum,
  columnSumValue,
  noResultsMessage,
  loading,
  years,
  data,
  name,
  onChange,
}) {
  return (
    <>
      <BSFiltersRow
        beFilters={beFilters}
        search={searchInput}
        yearSearch={years}
        multipeYears={multipeYears}
        selectors={selectors}
        dateRange={dateRange}
        onChange={onChange}
      />
      {data && (
        <Table
          onChange={onFilterHeaderChange}
          loading={loading}
          columnSumValue={columnSumValue}
          noResultsMessage={noResultsMessage}
          columnSum={columnSum}
          columns={columns}
          data={data}
          name={name}
        />
      )}
    </>
  );
}

export default Archive;
