import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RoleAccessControl } from 'SalesChallengeRefactor/common/accessControllers';
import BButton from 'common/components/BButton';
import { actionChallengeEnum, salesChallengeStatus } from 'SalesChallengeRefactor/models/enums';
import DownloadSalesReport from 'SalesChallengeRefactor/components/LayoutSalesChallenge/DownloadReport/DownloadReport';
import ReviewActionsContainer from 'SalesChallengeRefactor/components/ReviewActionsContainer';
import { roleIdEnum } from 'common/models/enums';

function ActionsGroup(props) {
  const {
    challengeStatus,
    processTypes,
    form,
    stateQuery,
    processYear,
    territoryStatus,
    formFiltersSalesChallenge,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [actionButtonParams, setActionButtonParams] = useState({
    statusId: undefined,
    requestParams: undefined,
    scope: undefined,
    name: undefined,
  });
  // const [disabledForProduct, setdisabledForProduct] = useState(true);
  const history = useHistory();
  const localData = JSON.parse(localStorage.getItem('user'));
  const { role } = localData;

  /* Manejo de props para botones de validación */
  useEffect(() => {
    /* Else if order should be on reverse of the Business Estructure order */
    if (stateQuery.clientId) {
      setActionButtonParams({
        requestParams: { clientid: stateQuery.clientId },
        scope: 'Client',
        name: 'validateClient',
      });
    } else if (stateQuery.territoryId) {
      setActionButtonParams({
        requestParams: { territoryId: stateQuery.territoryId },
        scope: 'Territory',
        name: 'validateTerritory',
      });
    } else if (stateQuery.subRegionId) {
      setActionButtonParams({
        requestParams: { subRegionId: stateQuery.subRegionId },
        scope: 'Subregion',
        name: 'validateSubregion',
      });
    }
  }, [stateQuery]);

  const actionType = useCallback(() => {
    if (challengeStatus) {
      const queryValues = form.getFieldsValue();
      const existZone = typeof queryValues.zoneId !== 'undefined';
      const processActive = processYear === parseInt(stateQuery.year);
      if (existZone && processActive) {
        const { statusId } = challengeStatus;

        const daysLeftRegister = processTypes?.REGISTER?.daysLeft;
        const daysLeftAdjust = processTypes?.ADJUST?.daysLeft;

        if (daysLeftRegister >= 0 && statusId === null) {
          return actionChallengeEnum.REGISTER;
        } else if (daysLeftRegister >= 0 && statusId === salesChallengeStatus.ON_PROCESS) {
          return actionChallengeEnum.UPDATE;
        } else if (
          daysLeftAdjust >= 0 &&
          (statusId === salesChallengeStatus.PARTIALLY_REJECTED ||
            statusId === salesChallengeStatus.REJECTED)
        ) {
          return actionChallengeEnum.ADJUSTED;
        }
      }
      return false;
    }
    return false;
  }, [challengeStatus, form, processTypes, processYear, stateQuery.year]);

  const handleActionChallenge = useCallback(() => {
    let action = actionType();
    if (action) {
      history.push(action.path);
    }
  }, [actionType, history]);
  useEffect(() => {
    // if (formFiltersSalesChallenge.productId !== undefined) {
    //   setdisabledForProduct(false);
    // } else {
    //   setdisabledForProduct(true);
    // }
  }, [formFiltersSalesChallenge]);
  return (
    <>
      {(role.roleId === roleIdEnum.ADMINISTRATOR ||
        (role.roleId === roleIdEnum.SALES_MANAGER &&
          JSON.parse(localStorage.allSubregionsAccess) === true)) &&
        territoryStatus === salesChallengeStatus.ON_SECOND_VALIDATION &&
        stateQuery.territoryId !== undefined && (
          <ReviewActionsContainer
            statusId={territoryStatus}
            requestParams={{ territoryId: stateQuery.territoryId }}
            scope={'General'}
            name={'ValidateTerritory'}
          />
        )}
      {actionType() && (
        <RoleAccessControl
          moduleKey="sales-challenge"
          page="register_sales-challenge"
          action="registerSalesChallenge"
        >
          <BButton
            onClick={handleActionChallenge}
            style={{ marginTop: '36px', marginLeft: '10px' }}
            type="primary"
            label={actionType().label ? actionType().label : ''}
          />
        </RoleAccessControl>
      )}
      <DownloadSalesReport
        style={{ marginTop: '36px' }}
        form={form}
        // disabled={disabledForProduct}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    stateQuery: state?.SALES_CHALLENGE?.query,
    processTypes: state?.SALES_CHALLENGE?.indicators?.processTypes?.data,
    processYear: state?.SALES_CHALLENGE?.indicators?.daysLeft?.data?.year,
    challengeStatus: state?.SALES_CHALLENGE?.views?.indicators?.data ?? null,
    territoryStatus: state?.SALES_CHALLENGE?.indicators?.territoryData?.validationStatusId,
  };
}

export default connect(mapStateToProps, {})(ActionsGroup);

ActionsGroup.propTypes = {
  challengeStatus: PropTypes.object,
};

ActionsGroup.defaultProps = {
  challengeStatus: {},
};
