import React from 'react';
import { Col, Row } from 'antd';
import TopFilterToRegister from '../TopFiltersGroupRegister';
import ColumnLeft from './ColumnLeft';
import MainTable from './MainTable';
import { twoColumns } from 'models/layout';
import IndicatorsSection from './IndicatorsSection';
import FooterActions from './FooterActions';
const { leftColumn, rightColumn } = twoColumns;

function LayoutSalesChallengeRegister(props) {
  const { onChangeActiveComponent, pageType } = props;

  return (
    <>
      <Row gutter={{ xs: 8, sm: 10, md: 16, lg: 18 }}>
        <Col {...{ sm: 24, xs: 24, lg: 14, md: 24 }}>
          <TopFilterToRegister
            onChangeActiveComponent={onChangeActiveComponent}
            pageType={pageType}
          />
        </Col>

        <Col {...{ sm: 24, xs: 24, lg: 10, md: 24 }}>
          <IndicatorsSection pageType={pageType} />
        </Col>
      </Row>

      <>
        <Row gutter={{ xs: 8, sm: 10, md: 16, lg: 18 }}>
          <Col {...leftColumn}>
            <ColumnLeft />
          </Col>
          <Col {...rightColumn}>
            <MainTable />
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: '2rem' }}>
          <Col {...{ md: 12, sm: 16, xs: 24 }}>
            <FooterActions pageType={pageType} />
          </Col>
        </Row>
      </>
    </>
  );
}

export default LayoutSalesChallengeRegister;
