import { combineReducers } from 'redux';
import catalogs from './catalogs';
import consultFilters from './consultFilters';
import tableComponents from './tableComponents';
import downloadExcel from './downloadExcel';

const rootReducers = combineReducers({
  catalogs: catalogs,
  consultFilters: consultFilters,
  downloadExcel: downloadExcel,
  tableComponents: tableComponents,
});

export default rootReducers;
