import request from 'services/request';
const URL_CATALOGS = '/Catalogs/Api/v1';
const FIXED_CATALOGS = `${URL_CATALOGS}/FixedCatalogs`;

export const paramsSerializer = (params) => {
  let arrayCollection = [];
  let singleCollection = [];
  const paramsKeys = Object.keys(params);

  paramsKeys.forEach((param) => {
    const paramValue = params[param];

    //If the param value is an array
    if (paramValue?.length) {
      const collectionOfFormattedStrings = paramValue.map((value, index) => {
        return `${param}=${value}`;
      });
      arrayCollection = [collectionOfFormattedStrings, ...arrayCollection];
    } else {
      singleCollection = [`${param}=${paramValue}`, ...singleCollection];
    }
  });

  let urlString = '';

  arrayCollection.forEach((arrayOfSameParams) => {
    arrayOfSameParams.forEach((parameter) => {
      urlString = `${urlString}&${parameter}`;
    });
  });

  singleCollection.forEach((part) => {
    urlString = `${urlString}&${part}`;
  });

  return urlString;
};

export async function getFixedCatalogByName({ cancelToken, catalogName, params, mode }) {
  let uri = `${FIXED_CATALOGS}/${catalogName}`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer: mode === 'multiple' ? paramsSerializer : undefined,
    params,
  });
}

export async function getCatalogByName({ cancelToken, catalogName, params, mode }) {
  let uri = `${URL_CATALOGS}/${catalogName}`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer: mode === 'multiple' ? paramsSerializer : undefined,
    params,
  });
}
