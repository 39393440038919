import React, { useCallback, useEffect, Suspense, useState } from 'react';
import { Col, Row, Form } from 'antd';
import { connect } from 'react-redux';
import { querySave, queryClean } from '../../redux/actions/query';
import { cleanTopFilters } from '../../redux/actions/filters';
import { setCurrentView } from '../../redux/actions/views';
import useWasRendered from '../../hooks/useWasRendered';
import useGetFilters from '../../hooks/useGetFilters';
import useFilters from '../../hooks/useFilters';
import ErrorBoundary from '../../common/ErrorBoundary';
import { ResetButton } from '../../common';
import { cleanInfo } from '../../redux/actions/info';
import { clientSelected, territorySelected } from '../../redux/actions/infoForSales';
import { setSubscriptionValue4Register } from '../../redux/actions/subscriptions';
import {
  daysLeftRequest,
  cleanIndicators,
  daysLeftTypesRequest,
} from 'SalesChallengeRefactor/redux/actions/indicators';
import {
  getProductsRequest,
  getChallengePogRequest,
} from 'SalesChallengeRefactor/redux/actions/registerSale';
import ActionsGroup from '../ActionsGroup';

const layoutFilters = {
  xs: 24,
  sm: 12,
  md: 6,
  lg: 4,
  xlg: 5,
};

function TopFiltersGroup(props) {
  const {
    queryClean: cleanQuery,
    querySave: saveQuery,
    cleanTopFilters: cleanFilters,
    setCurrentView: setView,
    onChangeActiveComponent,
    cleanInfo: infoClean,
    clientSelected: setClientSelected,
    territorySelected: setTerritorySelected,
    setSubscriptionValue4Register: setSubRegister,
    getChallengePogRequest: getPog,
    getProductsRequest: getProduct,
    daysLeftRequest: daysLeftReq,
    daysLeftTypesRequest: processTypes,
    cleanIndicators: indicatorsClean,
    // territoryId,
  } = props;
  const [form] = Form.useForm();
  const [formFiltersSalesChallenge, setformFiltersSalesChallenge] = useState({});
  const { wasRendered, setWasRendered } = useWasRendered();
  const [disabledFilters, setDisabledFilters] = useState({});
  const [allowRequest, setAllowRequests] = useState({
    year: false,
    regionId: false,
    lineId: false,
    subRegionId: false,
    territoryId: false,
    zoneId: false,
    clientId: false,
    businessRule: false,
    family: false,
    productId: false,
  });
  const [requestParamsSubscription, setRequestParamsSubscription] = useState({});

  const {
    orchestrateFiltersChange,
    orchestrateFiltersReset,
    initialDisabledFilters,
    initialAllowRequest,
    initialRequestParamsSubscription,
  } = useFilters();
  useEffect(() => {
    if (initialDisabledFilters && initialAllowRequest && initialRequestParamsSubscription) {
      setDisabledFilters({ ...initialDisabledFilters });
      setAllowRequests({ ...initialAllowRequest });
      setRequestParamsSubscription({ ...initialRequestParamsSubscription });
      setWasRendered(true);
    }
  }, [
    initialAllowRequest,
    initialDisabledFilters,
    initialRequestParamsSubscription,
    wasRendered,
    setWasRendered,
  ]);

  const getFilters = useGetFilters();
  const loadComponent = (componentName) => {
    const Component = React.lazy(() => import(`RootModule/catalogs/${componentName}`));
    return Component;
  };

  const cleanAllFilters = useCallback(() => {
    cleanFilters();
  }, [cleanFilters]);

  useEffect(() => {
    if (!wasRendered.current) {
      saveQuery({ state: 'idle', showBruteValue: true });
      cleanAllFilters();
      setWasRendered(true);
      daysLeftReq({ params: { processId: 3 } });
      processTypes({ params: { processId: 3 }, type: 'REGISTER' });
      processTypes({ params: { processId: 4 }, type: 'VALIDATE' });
      processTypes({ params: { processId: 5 }, type: 'ADJUST' });
    }
  }, [saveQuery, wasRendered, daysLeftReq, setWasRendered, cleanAllFilters, processTypes]);

  const handleOnFormValuesChange = ({ changedValues }) => {
    const currentChange = Object.keys(changedValues)[0];
    const formValues = form.getFieldsValue();
    setformFiltersSalesChallenge(formValues);
    const { clientId, productId, year, zoneId } = formValues;
    onChangeActiveComponent('sales-challenge');

    orchestrateFiltersChange({
      changedValues,
      currentChange,
      form,
      formValues,
      requestParamsSubscription,
      setAllowRequests,
      setClientSelected,
      setDisabledFilters,
      setRequestParamsSubscription,
      setSubRegister,
      daysLeftReq,
      setTerritorySelected,
    });

    //TODO: MOVE THIS INTO ORCHESTRATOR
    if (currentChange === 'businessRule') {
      setView('clientId');
    } else {
      setView(currentChange);
    }
    //get pog and product Info
    if (currentChange === 'productId') {
      getProduct({
        params: {
          zoneId,
          clientId,
          productId,
          year,
        },
      });
      getPog({
        params: {
          zoneId,
          clientId,
          productId,
          year,
        },
        isConsult: true,
      });
    }
    if (currentChange === 'zoneId') {
      daysLeftReq({ params: { processId: 5 } });
    }
    const queryValues = form.getFieldsValue();
    saveQuery({ ...queryValues, state: 'changed' });
    // unitl herae move to orchestarto
  };

  const generateFilters = () => {
    //TODO: Call Handle Changes Value insteas of repeating code
    const setAutomaticallyFirstOption = (options, filterName = 'nonde') => {
      if (options?.length > 0) {
        //* Set the value
        form.setFieldsValue({
          [filterName]: options[0]?.key,
        });
        const formValues = form.getFieldsValue();
        const currentChange = filterName;
        orchestrateFiltersChange({
          changedValues: { [filterName]: options[0]?.key },
          currentChange,
          form,
          formValues,
          requestParamsSubscription,
          setAllowRequests,
          setClientSelected,
          setDisabledFilters,
          setRequestParamsSubscription,
          setSubRegister,
          setTerritorySelected,
        });
        if (currentChange === 'businessRule') {
          setView('clientId');
        } else {
          setView(currentChange);
        }
        //get pog and product Info
        if (currentChange === 'productId') {
          getProduct({
            params: {
              zoneId: formValues?.zoneId,
              clientId: formValues?.clientId,
              productId: formValues?.productId,
            },
          });
          getPog({
            params: {
              zoneId: formValues?.zoneId,
              clientId: formValues?.clientId,
              productId: formValues?.productId,
              year: formValues?.year,
            },
            isConsult: true,
          });
        }
        if (currentChange === 'zoneId') {
          daysLeftReq({ params: { processId: 5 } });
        }
        const queryValues = form.getFieldsValue();
        saveQuery({ ...queryValues, state: 'changed' });
      }
    };

    const handleOnZoneChange = (_value, option) => {
      setSubRegister({ zoneLabel: option?.children });
    };

    const handleOnClientChange = (_value, option) => {
      setSubRegister({ clientId: option.key });
    };

    const handleOnYearChange = (_value, option) => {
      setSubRegister({ year: option.key });
    };

    const specificFilterProps = {
      year: {
        onChange: handleOnYearChange,
        onSuccess: (options) => setAutomaticallyFirstOption(options, 'year'),
      },
      zoneId: {
        onChange: handleOnZoneChange,
      },
      clientId: {
        onChange: handleOnClientChange,
      },
    };
    if (getFilters && getFilters.length > 0) {
      return getFilters.map((filter) => {
        let FilterComponent = loadComponent(filter.component);
        const filterName = filter?.enum?.name;
        return (
          <Col {...layoutFilters} key={filterName}>
            <FilterComponent
              askFromService={allowRequest?.[filterName]}
              name={filterName}
              disabled={disabledFilters?.[filterName]}
              requestParams={requestParamsSubscription?.[filterName]}
              allowClear={false}
              sourceId="Sales"
              {...specificFilterProps[filterName]}
            />
          </Col>
        );
      });
    }
    return null;
  };

  const handleResetButton = () => {
    form.resetFields();
    orchestrateFiltersReset({
      setDisabledFilters,
      setRequestParamsSubscription,
      setAllowRequests,
      form,
    });
    const formValues = form.getFieldsValue();
    cleanQuery({ state: 'idle', year: formValues.year, showBruteValue: true });
    cleanAllFilters();
    infoClean();
    indicatorsClean();
  };

  return (
    <>
      <Form
        name="validate_other"
        onValuesChange={(changedValues, allValues) =>
          handleOnFormValuesChange({ changedValues, allValues })
        }
        form={form}
        layout="horizontal"
      >
        <Row gutter={{ xs: 8, sm: 8, md: 10, lg: 12 }}>
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorBoundary>{generateFilters()}</ErrorBoundary>
          </Suspense>
          <Col style={{ marginTop: '36px' }}>
            <ResetButton onClick={handleResetButton} />
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ActionsGroup
              scope={'General'}
              form={form}
              formFiltersSalesChallenge={formFiltersSalesChallenge}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default connect(null, {
  queryClean,
  querySave,
  cleanTopFilters,
  setCurrentView,
  cleanInfo,
  clientSelected,
  territorySelected,
  setSubscriptionValue4Register,
  daysLeftRequest,
  getProductsRequest,
  getChallengePogRequest,
  cleanIndicators,
  daysLeftTypesRequest,
})(TopFiltersGroup);

TopFiltersGroup.propTypes = {};

TopFiltersGroup.defaultProps = {};
