import React, { useEffect } from 'react';
import { Form, Col, Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import BInput from 'common/components/BInput';
import { BSelect } from 'common/components';
import './style.css';

const inputSkeletonProps = {
  style: { width: '258px', margin: '0 auto' },
  active: true,
  size: 'large',
};

function ProductInfo(props) {
  const {
    isLoadingPrices,
    readOnly,
    productInfo,
    onFormValuesChange,
    onDefaultValuesChange,
    defaultValues,
    query,
    onQueryChange,
    onProductInfoDataChange,
    showBruteValue,
    pageType,
    ...other
  } = props;

  const [form] = Form.useForm();

  const filtersEnum = {
    COUNTRY: 'country',
    AP: 'crop',
    NET_PRICE: 'netPrice',
    MOQ: 'moq',
  };

  useEffect(() => {
    if (onDefaultValuesChange) onDefaultValuesChange({ defaultValues, form });
  }, [defaultValues, form, onDefaultValuesChange]);

  useEffect(() => {
    if (onQueryChange) onQueryChange({ form });
  }, [query, onQueryChange, form]);

  const handleOnValuesChange = ({ changedValues, allValues }) => {
    if (onFormValuesChange) onFormValuesChange({ changedValues, allValues, form });
  };

  useEffect(() => {
    if (productInfo?.data && onProductInfoDataChange) {
      onProductInfoDataChange({ productData: productInfo?.data, form: form });
    }
    //! DONT ADD onProductInfoDataChange as it will enter an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo, form]);

  return (
    <Card className="bg-agreements bg bproduct-info">
      <h3>Información de producto</h3>
      <Form
        onValuesChange={(changedValues, allValues) =>
          handleOnValuesChange({ changedValues, allValues })
        }
        form={form}
        {...other}
      >
        {productInfo?.isLoading && (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton.Input {...inputSkeletonProps} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton.Input {...inputSkeletonProps} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton.Input {...inputSkeletonProps} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton.Input {...inputSkeletonProps} />
            </div>
          </>
        )}
        {!productInfo?.isLoading && (
          <>
            {pageType !== 'Agreement' && (
              <>
                <Col span={24}>
                  <BSelect
                    name={filtersEnum.COUNTRY}
                    allowClear={false}
                    isRequired
                    label="País"
                    options={productInfo?.data?.countries}
                    disabled={readOnly}
                  />
                </Col>
                <Col span={24}>
                  <BSelect
                    placeholder=""
                    name={filtersEnum.AP}
                    label="AP"
                    options={productInfo?.data?.crops}
                    disabled={readOnly || productInfo?.data?.crops?.length === 0}
                  />
                </Col>
              </>
            )}

            {isLoadingPrices ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Skeleton.Input {...inputSkeletonProps} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Skeleton.Input {...inputSkeletonProps} />
                </div>
              </>
            ) : (
              <>
                <Col span={24}>
                  <BInput
                    name={filtersEnum.NET_PRICE}
                    label={showBruteValue ? 'Precio bruto' : 'Precio neto'}
                    disabled
                  />
                </Col>
                <Col span={24}>
                  <BInput name={filtersEnum.MOQ} disabled label="MOQ" loading />
                </Col>
              </>
            )}
          </>
        )}
      </Form>
    </Card>
  );
}

ProductInfo.propTypes = {
  readOnly: PropTypes.bool,
  pageType: PropTypes.string,
};

ProductInfo.defaultProps = {
  readOnly: true,
  pageType: 'Sales',
};

export default ProductInfo;
