import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BSelect } from 'common/components';
import { connect } from 'react-redux';
import { territoryRequest } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

function Territory(props) {
  const {
    territory,
    territoryRequest: getTerritory,
    askFromService,
    name,
    label,
    requestParams,
    onOptionsChange,
    sourceId,
    onSuccess,
    onChange,
    requestMode,
    allowClear,
    onValuesChange,
    ...other
  } = props;
  // const algo = Object.keys(requestParams ?? {});

  useEffect(() => {
    if (askFromService) {
      getTerritory({ requestParams, sourceId, onSuccess, mode: requestMode });
    }
  }, [getTerritory, askFromService, requestParams, sourceId, onSuccess, requestMode]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(territory.data ?? []);
    }
  }, [territory, onOptionsChange]);

  const handleOnChange = (value) => {
    if (onChange && typeof onChange === 'function')
      onChange(value, { options: territory?.data, name: props.name });
    if (onValuesChange && typeof onValuesChange === 'function') onValuesChange();
  };

  return (
    <BSelect
      onChange={handleOnChange}
      isLoading={territory.isLoading}
      disabled={territory.isLoading}
      allowClear={allowClear}
      name={name}
      label={label}
      options={territory.data}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

Territory.propTypes = {
  allowClear: PropTypes.bool,
  initialValue: PropTypes.any,
  territoryRequest: PropTypes.func,
  territory: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  sourceId: PropTypes.oneOf(['Agreements', 'Sales']),
};

Territory.defaultProps = {
  allowClear: true,
  initialValue: undefined,
  territoryRequest: null,
  territory: { data: [], isLoading: false },
  askFromService: true,
  label: filtersEnum.territory.label,
  name: filtersEnum.territory.name,
  requestParams: {},
  sourceId: 'Sales',
};

function mapStateToProps(state) {
  return {
    territory: state.APP?.catalogs?.territory,
  };
}

export default connect(mapStateToProps, { territoryRequest })(Territory);
