import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BSelect } from 'common/components';
import { connect } from 'react-redux';
import { subregionsRequest } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

function SubRegion(props) {
  const {
    subRegions,
    subregionsRequest: getSubRegions,
    askFromService,
    name,
    requestParams,
    sourceId,
    onOptionsChange,
    label,
    onSuccess,
    requestMode,
    onChange,
    ...other
  } = props;

  // const algo = Object.keys(requestParams ?? {});

  useEffect(() => {
    if (askFromService) {
      getSubRegions({ requestParams, sourceId, onSuccess, mode: requestMode });
    }
  }, [getSubRegions, askFromService, requestParams, sourceId, onSuccess, requestMode]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(subRegions.data ?? []);
    }
  }, [subRegions, onOptionsChange]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: subRegions?.data, name: props.name });
  };

  return (
    <BSelect
      isLoading={subRegions.isLoading}
      disabled={subRegions.isLoading}
      name={name}
      options={subRegions.data}
      label={label}
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={handleOnChange}
      {...other}
    />
  );
}

SubRegion.propTypes = {
  subregionsRequest: PropTypes.func,
  subRegions: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  sourceId: PropTypes.oneOf(['Agreements', 'Sales', 'Catalogs']),
};

SubRegion.defaultProps = {
  subregionsRequest: null,
  subRegions: { data: [], isLoading: false },
  askFromService: true,
  label: filtersEnum.subRegion.label,
  name: filtersEnum.subRegion.name,
  requestParams: {},
  sourceId: 'Sales',
};

function mapStateToProps(state) {
  return {
    subRegions: state.APP?.catalogs?.subregions,
  };
}

export default connect(mapStateToProps, { subregionsRequest })(SubRegion);
