import { fork, all } from 'redux-saga/effects';

import * as catalogs from './catalogs';
import * as consultFilters from './consultFilters';
import * as tableComponents from './tableComponents';
import * as downloadExcel from './downloadExcel';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(catalogs),
      ...Object.values(consultFilters),
      ...Object.values(downloadExcel),
      ...Object.values(tableComponents),
    ].map(fork)
  );
}
