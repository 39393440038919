import { request } from '../services';

export async function getTerritories() {
  return request
    .get(`/Catalogs/Api/v1/FixedCatalogs/Territories?includeall=true`)
    .then((response) => {
      return response.data;
    });
}

export async function getZones(territory) {
  return request
    .get(`/Catalogs/Api/v1/FixedCatalogs/Zones?Territories=${territory}`)
    .then((response) => {
      return response.data;
    });
}

export async function getClients() {
  return request.get(`/Catalogs/Api/v1/Clients`).then((response) => {
    return response.data;
  });
}

export async function getProducts(BusinessLineId) {
  return request
    .get(`/Catalogs/Api/v1/Products?BusinessLines${BusinessLineId}`)
    .then((response) => {
      return response.data;
    });
}

export async function registerSaleGoal(payload) {
  return request.post(`/Catalogs/Api/v1/SalesGoals`, payload).then((response) => {
    return response;
  });
}

export async function getSalesGoalYear() {
  return request.get(`/Catalogs/Api/v1/SalesGoals/SalesGoalsYears`).then((response) => {
    return response;
  });
}

export async function validateExistSale(TerritoryId, lineId, zoneId, soldTo, productId) {
  return request
    .get(
      `/Catalogs/Api/v1/SalesGoals/ValidateExists?TerritoryId=${TerritoryId}&LineId=${lineId}&ZoneId=${zoneId}&SoldTo=${soldTo}&ProductNumber=${productId}`
    )
    .then((response) => {
      return response;
    });
}
